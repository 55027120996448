import React, { FC, useMemo } from 'react';
import { ChatBox } from './components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Store } from '../../../redux';
import { ErrorLayout } from '../../../shared/components';
import { limit, or, orderBy, where } from 'firebase/firestore';
import { useAuthState } from 'react-firebase-hooks/auth';
import { firebaseAuth } from '../../../shared/lib';
import { Box, styled, Theme, useMediaQuery } from '@mui/material';
import { DMPage } from './dm';
import { CHAT_PAGE_ROUTE, DM_PAGE_ROUTE } from './route.definitions';

interface ChatPageProps {
  avoidErrorMessage?: boolean;
}

export const ChatPage: FC<ChatPageProps> = ({ avoidErrorMessage = false }) => {
  const [params] = useSearchParams();
  const receiverID = params.get('receiverID');
  const { room } = useSelector((state: Store) => state.inPlayReducer.gameInfo);

  const [user] = useAuthState(firebaseAuth);

  const queryConstraint = useMemo(() => {
    if (!receiverID?.length && !room?.roomID) {
      return [];
    }

    if (!user) return [];

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const qc: any[] = [orderBy('createdAt', 'desc'), limit(50)];
    if (receiverID) {
      qc.push(
        or(
          where('threadID', '==', [receiverID, user.uid]),
          where('threadID', '==', [user.uid, receiverID])
        )
      );
      return qc;
    }
    if (room?.roomID) {
      qc.push(where('groupID', '==', room.roomID));

      return qc;
    }

    return [];
  }, [receiverID, room?.roomID, user]);

  if (!receiverID?.length && !room?.roomID && !avoidErrorMessage) {
    return (
      <ErrorLayout
        outerBoxProps={{ height: 'auto' }}
        texts={{
          defaultError: `Missing user info to start a chat`,
        }}
      />
    );
  }

  return (
    <ChatBox
      sendMessageProps={{
        receiverID: receiverID || '',
        roomID: room?.roomID || '',
      }}
      queryConstraint={queryConstraint}
    />
  );
};

export const LgScreenChatPage: FC = () => {
  const [search] = useSearchParams();
  const missingUID = !search.get('receiverID');
  const nav = useNavigate();

  const onClickThread = (id?: string) => () => {
    if (!id) {
      return;
    }

    const search = new URLSearchParams({ receiverID: id }).toString();
    nav(DM_PAGE_ROUTE.ABSOLUTE_PATH + `?${search}`);
  };

  return (
    <LgScreenChatPageLayout className="container mx-auto">
      <DMPage
        onClickThread={onClickThread}
        pageContentWrapperProps={{
          sx: theme => ({
            display: 'flex',
            flexDirection: 'column',
            gap: theme.spacing(1),
            overflowY: 'auto',
          }),
        }}
        textFieldProps={{
          sx: { width: '100%' },
        }}
      />
      <div className="bg-gray-600 w-full" />
      {missingUID ? (
        <ErrorLayout
          level="info"
          outerBoxProps={{
            height: 'calc(100vh - 80px)',
            justifyContent: 'flex-start',
          }}
          texts={{
            defaultError:
              'Select a user from the list to open a chat thread. You can then start your conversation.',
          }}
        />
      ) : (
        <ChatPage avoidErrorMessage />
      )}
    </LgScreenChatPageLayout>
  );
};

const LgScreenChatPageLayout = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '350px 1px 1fr',
  gap: theme.spacing(1),
}));

export const BhabiDMPage: FC = () => {
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
  const nav = useNavigate();

  const onClickThread = (id?: string) => () =>
    nav(CHAT_PAGE_ROUTE.ABSOLUTE_PATH + `?receiverID=${id}`);

  return !isMobile ? (
    <LgScreenChatPage />
  ) : (
    <DMPage onClickThread={onClickThread} />
  );
};
