import { RouteObject } from 'react-router-dom';
import React from 'react';
import { CREATE_ROOM_ROUTE } from './route.definitions';
import { CreateRoomPage } from './page';

export const createRoomRoutes = (
  el: JSX.Element = <CreateRoomPage />
): RouteObject => ({
  path: CREATE_ROOM_ROUTE.ABSOLUTE_PATH,
  element: el,
});
