import { PushNotifications } from '@capacitor/push-notifications';
import { useHttpMutation } from '../../api';
import { API_URLS } from '../consts';
import { useCallback, useEffect, useRef } from 'react';
import { LoggerService } from '../logger';
import { Capacitor } from '@capacitor/core';
import { Store } from '../../redux';
import { useDispatch, useSelector } from 'react-redux';
import { BhabiStorage } from './storage';
import { useNavigate } from 'react-router-dom';
import { getUser } from '../../redux/reducers';
import { AnyAction } from '@reduxjs/toolkit';

export const usePushNotifications = () => {
  const { mutate: updateToken } = useHttpMutation<
    { message: string },
    { token: string; device: string }
  >(API_URLS.IOS_NOTIFICATION_TOKEN, 'POST');

  const { isInRoom } = useSelector((state: Store) => state.userReducer);
  const { mutate: reJoinRoom } = useHttpMutation<
    { token: string },
    { roomID: string }
  >(API_URLS.RE_JOIN_ROOM, 'POST');
  const nav = useNavigate();
  const dispatch = useDispatch();

  const pushNotificationRef = useRef<string>('');

  const addListeners = useCallback(async () => {
    await PushNotifications.addListener('registration', token => {
      LoggerService.info('Push notification registering');
      if (pushNotificationRef.current === token.value) {
        return;
      }
      pushNotificationRef.current = token.value;

      updateToken(
        { token: token.value, device: Capacitor.getPlatform() },
        {
          onSuccess: () => {
            LoggerService.info('Push notification registered');
          },
          onError: () => {
            LoggerService.error('Push notification registration failed');
          },
        }
      );
    });

    await PushNotifications.addListener('registrationError', err => {
      LoggerService.error('Registration error: ', err.error);
    });

    await PushNotifications.addListener(
      'pushNotificationActionPerformed',
      async ({ notification }) => {
        LoggerService.info('Push notification received', !!notification.data);
        const { roomID } = notification.data || {};
        if (isInRoom || !roomID || Capacitor.getPlatform() !== 'ios') {
          return;
        }

        reJoinRoom(
          { roomID },
          {
            onSuccess: res => {
              BhabiStorage.setToken(res.token);
              dispatch(getUser(res.token) as unknown as AnyAction);
              nav('/in-play');
            },
            onError: () => {
              LoggerService.error('Re-join room failed');
            },
          }
        );
      }
    );
  }, [dispatch, isInRoom, nav, reJoinRoom, updateToken]);

  const registerNotifications = useCallback(async () => {
    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      LoggerService.error('No notification permissions granted');
      return;
    }

    await PushNotifications.register();
    await addListeners();
  }, [addListeners]);

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) {
      return;
    }
    (async function () {
      await registerNotifications();
    })();
  }, [registerNotifications]);
};
