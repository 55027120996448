import React, { FC, useRef } from 'react';
import { motion, Variants } from 'framer-motion';
import { isEqual, noop } from 'lodash';

import { Card } from '../../types';
import { CardsLayoutOptions, useCardsLayout } from '../hooks';
import { DeckCard } from './deck-card';
import { useThrowCard } from '../context';

export interface CardPosition {
  top: number;
  right: number;
  left: number;
  bottom: number;
}
export interface CardsWithLayoutProps extends Partial<CardsLayoutOptions> {
  cards: (Card & { disabled?: boolean })[];
  selectedCard?: Card | null;
  onClickCard?: (card: Card, pos: CardPosition) => () => void;
  lockDrag?: boolean;
}

export const CardsWithLayout: FC<CardsWithLayoutProps> = ({
  cards,
  selectedCard = null,
  onClickCard = () => noop,
  layout,
  lockDrag = false,
}) => {
  const { wrapperRef, variants } = useCardsLayout(
    cards,
    layout ? { layout } : undefined
  );

  const { thrownCard } = useThrowCard();

  const dragWidth = cards?.length * 25;

  return (
    <motion.section
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-end',
      }}
      ref={wrapperRef}
      {...{
        ...(!lockDrag && {
          drag: 'x',
          dragConstraints: {
            left: -dragWidth / 3,
            right: dragWidth / 2,
          },
        }),
      }}
    >
      {cards?.map(({ disabled, ...card }, i) => {
        return (
          <React.Fragment key={i}>
            {!isEqual(card, selectedCard) && !isEqual(thrownCard, card) && (
              <UserDeckCard
                card={card}
                disabled={disabled || isEqual(thrownCard, card)}
                onClickCard={onClickCard}
                index={i}
                variants={variants}
              />
            )}
          </React.Fragment>
        );
      })}
    </motion.section>
  );
};

interface UserDeckCardProps {
  card: Card;
  variants: Variants;
  disabled: boolean;
  onClickCard: (card: Card, pos: CardPosition) => () => void;
  index: number;
}

const UserDeckCard: FC<UserDeckCardProps> = ({
  card,
  variants,
  disabled,
  onClickCard,
  index: i,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  const onClick = () => {
    if (ref.current) {
      const pos = ref.current.getBoundingClientRect();
      onClickCard(card, pos)();
    }
  };

  return (
    <DeckCard
      cardRef={ref}
      card={card}
      onClick={onClick}
      disabled={disabled}
      motionDivProps={{
        animate: 'show',
        initial: 'hidden',
        custom: { i },
        exit: 'hidden',
        variants,
        style: {
          position: 'absolute',
          transformOrigin: 'center bottom',
        },
        transition: {
          type: 'spring',
        },
      }}
    />
  );
};
