import React, { FC, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Store } from '../../../../redux';
import {
  Box,
  Button,
  LinearProgress,
  Paper,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { Texts } from '../../../../shared/types';
import { botImage } from '../../../../assets';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useHttpMutation } from '../../../../api';
import { API_URLS } from '../../consts';
import { useNavigate } from 'react-router-dom';
import { useAlert } from '../../../../shared/components';

export declare type InviteBotsInput = {
  numberOfBots: number;
};

type InviteBotsText = Texts<'subText' | 'submit' | 'warning'>;
export interface InviteBotsProps {
  texts?: InviteBotsText;
}

const defaultText: InviteBotsText = {
  subText: `Meet Omega, the bot. It's a great way to play when you don't have enough players. 
  Select number of bots and start playing.`,
  submit: 'Submit',
  warning: 'You need to be in a room to invite bots.',
};

export const InviteBots: FC<InviteBotsProps> = ({ texts = defaultText }) => {
  const {
    gameInfo: { room },
    players,
    isInRoom,
  } = useSelector((state: Store) => ({
    ...state.inPlayReducer,
    isInRoom: !!state.userReducer.isInRoom,
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<InviteBotsInput>({
    mode: 'onChange',
    defaultValues: {
      numberOfBots: NaN,
    },
  });

  const { mutate, isLoading } = useHttpMutation<unknown, InviteBotsInput>(
    API_URLS.INVITE_BOTS,
    'POST'
  );

  const maxBotsAllowed = useMemo(
    () => (room?.numberOfPlayers ? room.numberOfPlayers - players.length : 1),
    [players.length, room?.numberOfPlayers]
  );

  const { addAlert } = useAlert();
  const nav = useNavigate();

  const onSubmit: SubmitHandler<InviteBotsInput> = data => {
    mutate(
      {
        numberOfBots: Number(data.numberOfBots),
      },
      {
        onError: () => {
          addAlert({
            message: 'Error inviting bots',
            type: 'error',
          });
        },
        onSuccess: () => {
          nav('/in-play');
        },
      }
    );
  };

  const botImageWithSubText = (
    <Box display="grid" gridTemplateRows="auto" rowGap={1}>
      <img
        src={botImage}
        alt="bot"
        style={{ maxWidth: '100%', height: 'auto', maxHeight: 118, alignSelf: 'center' }}
      />
      <Typography variant="body2">{texts.subText}</Typography>
    </Box>
  );

  return (
    <InviteBotsStyled>
      <Box className="__content" component={Paper} px={3}>
        {botImageWithSubText}
        {!isInRoom && (
          <Typography variant="body2" color="warning.main">
            {texts.warning}
          </Typography>
        )}
        <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
          <TextField
            type="number"
            {...register('numberOfBots', {
              required: 'Number of bots is required',
              min: 1,
              max: maxBotsAllowed,
            })}
            error={!!errors.numberOfBots}
            disabled={!isInRoom}
            placeholder="Number of bots"
            sx={{
              pb: 3,
              width: {
                xs: '100%',
              },
            }}
            helperText={
              <Box
                sx={{
                  color: theme =>
                    errors?.numberOfBots?.message
                      ? theme.palette.error.main
                      : theme.palette.warning.main,
                }}
                component="span"
              >
                {errors.numberOfBots?.message ||
                  (isInRoom && `Max bots allowed: ${maxBotsAllowed}`)}
              </Box>
            }
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!isInRoom || isLoading}
            fullWidth
          >
            {texts.submit}
          </Button>
          {isLoading && (
            <LinearProgress sx={{ marginTop: 1 }} color="secondary" />
          )}
        </Stack>
      </Box>
    </InviteBotsStyled>
  );
};

const InviteBotsStyled = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(0.5),
  '& .__content': {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(1),
    padding: theme.spacing(2.5),
    width: '50%',
    margin: 'auto',
    maxWidth: 400,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
}));
