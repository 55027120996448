import { RouteObject } from 'react-router-dom';
import React from 'react';
import { RESET_PASSWORD_ROUTES, SIGN_IN_ROUTES } from './route.definitions';
import { SignInPage } from './page';
import { ResetPasswordPage } from './reset-password';
import { ErrorLayout } from '../../../shared/components';

export const signInRoutes: RouteObject = {
  path: SIGN_IN_ROUTES.ABSOLUTE_PATH,
  element: <SignInPage />,
  errorElement: <ErrorLayout />,
};

export const resetPasswordRoutes: RouteObject = {
  path: RESET_PASSWORD_ROUTES.ABSOLUTE_PATH,
  element: <ResetPasswordPage />,
  errorElement: <ErrorLayout />,
};
