import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../../redux';
import { useEffect } from 'react';
import { useFirebase } from '../lib';
import { getStoredMode, getUser, updateUserState } from '../../redux/reducers';
import { AnyAction } from '@reduxjs/toolkit';

export const useIsAuth = () => {
  const { userReducer: user } = useSelector((state: Store) => state);
  const { isProcessing, checkFirebaseAuth } = useFirebase();
  const dispatch = useDispatch();

  useEffect(() => {
    checkFirebaseAuth(isAuth => {
      if (!isAuth) {
        dispatch(updateUserState({ isIdle: false }));
      }
    });
    dispatch(getStoredMode() as unknown as AnyAction);
  }, [checkFirebaseAuth, dispatch]);

  return {
    isAuthenticated: user?.isAuthenticated,
    isLoading: user?.isLoading || isProcessing,
    isError: user?.isError,
    token: user?.token,
    user,
    getUser,
    isIdle: user?.isIdle,
  };
};
