import { Box, Button, styled } from '@mui/material';

export const NotificationStyled = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: 'auto',
  gap: theme.spacing(0.5),
  padding: theme.spacing(1),
  maxWidth: 500,
}));

export const roomInfoGridClassNames = {
  infoBox: {
    name: 'info_box',
    labelText: 'label_text',
    infoText: 'info_text',
  },
};

export const RoomInfoGridStyled = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '50% 50%',
  gap: theme.spacing(1),
  '& .info_box': {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(0.5),
    '& .info_text': {
      color: theme.palette.text.secondary,
    },

    '& ::before': {
      content: '""',
      position: 'absolute',
      top: 0,
      right: 0,
      height: '100%',
      width: 2,
      backgroundColor: theme.palette.text.secondary,
    },
  },
}));

export const ActionButtonStyled = styled(Button)(({ theme }) => ({
  padding: theme.spacing(1),
  height: 30,
}));
