import { useCallback } from 'react';
import { api } from '../../../api';
import { API_URLS } from '../consts';
import { useCreateRoom } from '../create-room';
import { InviteBotsInput } from '../invite-players/components';
import { useAlert } from '../../../shared/components';
import { BhabiStorage } from '../../../shared/lib';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { updateUserState } from '../../../redux/reducers';
import { IN_PLAY_ROUTE } from '../in-play';
import { useMutation } from 'react-query';
import { omit } from 'lodash';
import { AxiosError } from 'axios';

const useInviteBots = () => {
  return useMutation<unknown, AxiosError, InviteBotsInput & { token: string }>({
    mutationFn: async data => {
      const res = await api.post(API_URLS.INVITE_BOTS, omit(data, 'token'), {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      });

      return res.data;
    },
  });
};

export const useQuickPlay = () => {
  const { mutate: createRoom, ...creteRoomRem } = useCreateRoom();
  const { mutate, ...inviteBotsRem } = useInviteBots();

  const { addAlert } = useAlert();
  const dispatch = useDispatch();
  const nav = useNavigate();

  const quickPlay = useCallback(() => {
    createRoom(
      { NumberOfPlayers: 5 },
      {
        onError: () => {
          addAlert({
            message: 'Error creating quick play',
            type: 'error',
          });
        },
        onSuccess: res => {
          // update token, this token will be used to join the room

          mutate(
            { numberOfBots: 4, token: res.token },
            {
              onError: () => {
                addAlert({
                  message: 'Error inviting bots',
                  type: 'error',
                });
              },
              onSuccess: () => {
                BhabiStorage.setToken(res.token);
                dispatch(
                  updateUserState({
                    isInRoom: true,
                    token: res.token,
                  })
                );
                nav(IN_PLAY_ROUTE.ABSOLUTE_PATH);
              },
            }
          );
        },
      }
    );
  }, [addAlert, createRoom, dispatch, mutate, nav]);

  return {
    quickPlay,
    isLoading: creteRoomRem.isLoading || inviteBotsRem.isLoading,
    isError: creteRoomRem.isError || inviteBotsRem.isError,
  };
};
