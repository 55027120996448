export const CHAT_PAGE_ROUTE = {
  TITLE: 'Chat',
  ABSOLUTE_PATH: '/dm/chat',
  EXACT_PATH: '/dm/chat',
};

export const DM_PAGE_ROUTE = {
  TITLE: 'DM',
  ABSOLUTE_PATH: '/dm',
  EXACT_PATH: '/dm',
};
