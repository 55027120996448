import { Button, ButtonProps, Typography } from '@mui/material';
import React, { FC, ImgHTMLAttributes } from 'react';
import googleLogo from '../../../assets/google-logo.png';
import { useMutation } from 'react-query';
import { firebaseAuth } from '../../../shared/lib';
import {
  GoogleAuthProvider,
  UserCredential,
  signInWithCredential,
} from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { LoggerService } from '../../../shared/logger';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { Texts } from '../../../shared/types';
import { blue } from '@mui/material/colors';

export const useLoginWithGoogle = () =>
  useMutation<UserCredential, FirebaseError>({
    mutationKey: 'login-with-google',
    mutationFn: async () => {
      const result = await FirebaseAuthentication.signInWithGoogle();
      const credential = GoogleAuthProvider.credential(
        result.credential?.idToken
      );
      return signInWithCredential(firebaseAuth, credential);
    },
  });

export const ContinueWithGoogleButton: FC = () => {
  const { mutate: loginWithGoogle, isLoading, isError } = useLoginWithGoogle();

  const handleLoginWithGoogle = () => {
    loginWithGoogle(undefined, {
      onError: error => {
        LoggerService.error('Error logging in with Google', error);
      },
    });
  };

  return (
    <ContinueWithButton
      {...{
        buttonProps: {
          onClick: handleLoginWithGoogle,
          disabled: isLoading,
        },
        imgProps: {
          src: googleLogo,
          alt: 'Google Logo',
          style: { width: 20, height: 20 },
        },
        isError,
      }}
    />
  );
};

interface ContinueWithButtonProps {
  buttonProps: ButtonProps;
  imgProps?: ImgHTMLAttributes<HTMLImageElement>;
  texts?: Texts<'title' | 'errorMessage'>;
  error?: FirebaseError | null;
  isError?: boolean;
}

export const ContinueWithButton: FC<ContinueWithButtonProps> = ({
  buttonProps,
  imgProps,
  texts = {
    title: 'Continue with Google',
    errorMessage: `Error signing in with Google, please try again.`,
  },
  isError = false,
}) => (
  <>
    <Button
      variant="contained"
      color="secondary"
      fullWidth
      size="small"
      sx={{
        bgcolor: blue[800],
      }}
      startIcon={<img {...imgProps} />}
      {...buttonProps}
    >
      {texts.title}
    </Button>
    {isError && (
      <Typography color="error.main" variant="body2">
        {texts.errorMessage}
      </Typography>
    )}
  </>
);
