import { Outlet, RouteObject } from 'react-router-dom';
import React from 'react';
import { CHAT_PAGE_ROUTE, DM_PAGE_ROUTE } from './route.definitions';
import { BhabiDMPage, ChatPage } from './page';
import { BhabiAppLayout } from '../components';

export const chatRoutes = (el: JSX.Element = <ChatPage />): RouteObject => ({
  path: DM_PAGE_ROUTE.ABSOLUTE_PATH,
  element: (
    <BhabiAppLayout>
      <Outlet />
    </BhabiAppLayout>
  ),
  children: [
    {
      index: true,
      element: <BhabiDMPage />,
    },
    {
      path: CHAT_PAGE_ROUTE.ABSOLUTE_PATH,
      element: el,
    },
  ],
});
