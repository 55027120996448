import { IconButton, IconButtonProps, useTheme } from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import React, { FC, useEffect, useState } from 'react';

interface ConfirmDeleteButtonProps {
  iconButtonProps?: Omit<IconButtonProps, 'onClick'>;
  onClick: () => void;
}

export const ConfirmDeleteButton: FC<ConfirmDeleteButtonProps> = ({
  iconButtonProps,
  onClick,
}) => {
  const theme = useTheme();
  const [clickCount, setClickCount] = useState<number>(0);
  const [iconColor, setIconColor] = useState<string>(
    theme.palette.text.primary
  );

  useEffect(() => {
    if (clickCount === 1) {
      const timeout = setTimeout(() => {
        setClickCount(0);
        setIconColor(theme.palette.text.primary);
      }, 3000);

      return () => clearTimeout(timeout);
    }
  }, [clickCount, theme.palette.text.primary]);

  const handleOnClick: React.MouseEventHandler<HTMLButtonElement> = e => {
    e.stopPropagation();
    e.preventDefault();
    if (clickCount === 0) {
      setClickCount(1);
      setIconColor(theme.palette.warning.main);
      return;
    }

    onClick();
  };

  return (
    <IconButton {...iconButtonProps} onClick={handleOnClick}>
      <DeleteForeverIcon
        fontSize="small"
        sx={{
          color: iconColor,
        }}
      />
    </IconButton>
  );
};
