import {
  Box,
  Button,
  Paper,
  Tab,
  Tabs,
  Typography,
  styled,
} from '@mui/material';
import React, { FC, useMemo } from 'react';
import { InviteBots, InvitePlayerPageLayout, SearchUser } from './components';
import { useSearchUser } from './hooks';
import { useHttpMutation } from '../../../api';
import { API_URLS } from '../consts';
import {
  CustomTabPanel,
  UserRooms,
  tabProps,
  useTabChange,
} from '../components';
import { useSelector } from 'react-redux';
import { Store } from '../../../redux';
import { PUBLIC_PROFILE_ROUTE } from '../profile';
import {
  BhabiLink,
  ErrorLayout,
  LoadingLayout,
  UserAvatar,
  useAlert,
} from '@/shared/components';

export const InviteBoxStyled = styled(Box)(() => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr auto',
  gap: 1,
  minHeight: 70,
  maxWidth: 500,
  alignItems: 'center',
}));

const TABS_NAMES = ['Users', 'Bots', 'Rooms'];

const useInvitePlayer = () =>
  useHttpMutation<unknown, { to: string }>(API_URLS.INVITE, 'POST');

export const InvitePlayersPage: FC = () => {
  const { handleTabChange, value } = useTabChange(TABS_NAMES);

  return (
    <>
      <Tabs
        onChange={handleTabChange}
        aria-label="basic tabs"
        value={value}
        variant="fullWidth"
      >
        <Tab label="Users" {...tabProps(0)} />
        <Tab label="Bots" {...tabProps(1)} />
        <Tab label="Rooms" {...tabProps(2)} />
      </Tabs>
      <CustomTabPanel value={value} index={0}>
        <InviteUsers />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <InviteBots />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <UserRooms />
      </CustomTabPanel>
    </>
  );
};

export const InviteUsers: FC = () => {
  const { onChange, userInfo, noUserFound, ...query } = useSearchUser();
  const { mutate: invite } = useInvitePlayer();
  const { addAlert } = useAlert();

  const { isInRoom, players } = useSelector((state: Store) => ({
    ...state.userReducer,
    ...state.inPlayReducer,
  }));

  const onClickInvite: (
    to: string
  ) => React.MouseEventHandler<HTMLButtonElement> = to => e => {
    e.stopPropagation();
    invite(
      { to },
      {
        onSuccess: () => {
          addAlert({
            type: 'success',
            message: 'Invitation sent',
          });

          query.refetch();
        },
        onError: err => {
          addAlert({
            type: 'error',
            message: err.response?.data?.message || 'Something went wrong',
          });
        },
      }
    );
  };

  const filterJoinedPlayers = useMemo(
    () =>
      isInRoom
        ? userInfo?.filter(
            user => !players.some(player => player.id === user.id)
          )
        : userInfo,
    [players, userInfo, isInRoom]
  );

  if (query.isError) {
    return <ErrorLayout outerBoxProps={{ height: 'auto' }} />;
  }

  return (
    <>
      <InvitePlayerPageLayout
        head={
          <div className="text-center">
            <SearchUser
              textFieldProps={{
                onChange,
              }}
            />
            {!isInRoom && (
              <Typography color="warning.main" textAlign="center">
                Create a room first to invite players
              </Typography>
            )}
          </div>
        }
      >
        {filterJoinedPlayers?.map((user, i) => (
          <InviteBoxStyled component={Paper} key={i} px={2}>
            <Box mr={0.5}>
              <BhabiLink
                to={[
                  PUBLIC_PROFILE_ROUTE.EXACT_PATH,
                  '?user_id=',
                  user.id,
                ].join('')}
                style={{ textDecoration: 'none' }}
              >
                <UserAvatar
                  {...{
                    photoURL: user.photoURL,
                    name: user.name,
                    userState: user.state,
                  }}
                />
              </BhabiLink>
            </Box>
            <Box>
              <BhabiLink
                to={[
                  PUBLIC_PROFILE_ROUTE.EXACT_PATH,
                  '?user_id=',
                  user.id,
                ].join('')}
                style={{ textDecoration: 'none' }}
              >
                <Typography variant="h6" fontWeight={500}>
                  {user.name}
                </Typography>
              </BhabiLink>
              <Typography variant="body2">{user.email}</Typography>
            </Box>
            <Button
              variant="outlined"
              size="small"
              onClick={onClickInvite(user.id)}
              disabled={!isInRoom}
              sx={{
                height: 30,
              }}
            >
              Invite
            </Button>
          </InviteBoxStyled>
        ))}
        {noUserFound && !query.isLoading && (
          <Typography variant="body2" color="text.secondary">
            No user found
          </Typography>
        )}
      </InvitePlayerPageLayout>
      {query.isLoading && !query.isError && (
        <LoadingLayout sx={{ height: 'auto' }} />
      )}
    </>
  );
};
