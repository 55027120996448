import { Box, styled } from '@mui/material';
import React, { FC, PropsWithChildren, ReactNode } from 'react';

export const InvitePlayerPageWrapper = styled(Box)(() => ({
  position: 'relative',
}));

export const InvitePlayerPageContentWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: theme.spacing(2),
  gridTemplateRows: 'auto',
  '& > div': {
    selfAlign: 'center',
    justifySelf: 'center',
    width: '100%',
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: '1fr',
  },
}));

export const InvitePlayerPageLayout: FC<
  PropsWithChildren<{ head?: ReactNode }>
> = ({ children, head }) => (
  <InvitePlayerPageWrapper px={2} className="container mx-auto">
    {head}
    <InvitePlayerPageContentWrapper pt={2} pb={4}>
      {children}
    </InvitePlayerPageContentWrapper>
  </InvitePlayerPageWrapper>
);
