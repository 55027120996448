import { Box, ClickAwayListener, Paper, Popper } from '@mui/material';
import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import { MessageIcon } from '../../../../assets';
import { ChatBox, useFirestoreQuery } from '../../chat/components';
import { limit, orderBy, where } from 'firebase/firestore';
// import { useInPlayPageAdMob } from '../../hooks/use-in-play-admob';

interface GroupChatProps {
  roomID: string;
}

const menuId = 'primary-notification-for-messages';

export const GroupChat: FC<GroupChatProps> = ({ roomID }) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [clickedOnIcon, setClickedOnIcon] = useState(false);
  const isInviteMenuOpen = Boolean(anchorEl);
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setClickedOnIcon(true);
    setAnchorEl(!anchorEl ? event.currentTarget : null);
  };

  const anchorRef = useRef<HTMLElement>(null);
  // const showAd = useInPlayPageAdMob();

  // useEffect(() => {
  //   showAd(isInviteMenuOpen);
  // }, [isInviteMenuOpen, showAd]);

  const queryConstraint = useMemo(
    () => [
      orderBy('createdAt', 'desc'),
      limit(50),
      where('groupID', '==', roomID),
    ],
    [roomID]
  );

  const messages = useFirestoreQuery(queryConstraint);

  useEffect(() => {
    if (!messages.length || !anchorRef.current) {
      return;
    }

    setAnchorEl(anchorRef.current);
  }, [messages]);

  return (
    <>
      <Box onClick={handleMenuOpen} ref={anchorRef}>
        <MessageIcon
          fontSize="large"
          variant="outlined"
          sx={theme => ({ color: theme.palette.primary.main })}
        />
      </Box>
      <Popper
        open={isInviteMenuOpen}
        anchorEl={anchorEl}
        placement="top-end"
        id={menuId}
        sx={{
          zIndex: 5000,
        }}
      >
        <ClickAwayListener
          onClickAway={() => {
            if (!clickedOnIcon) setAnchorEl(null);
            setClickedOnIcon(false);
          }}
        >
          <Box
            mx={0.5}
            component={Paper}
            elevation={6}
            p={1}
            width={320}
            overflow="auto"
          >
            <ChatBox
              queryConstraint={queryConstraint}
              outerBoxProps={{
                className: 'lg:h-[500px] sm:h-[325px] flex flex-col',
              }}
              sendMessageProps={{
                roomID,
                messageFormProps: {
                  height: 75,
                },
              }}
            />
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
