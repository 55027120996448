import React, { Box, alpha, styled } from '@mui/material';
import { FC } from 'react';
import { TOC_ROUTES } from '../../screens';
import { Capacitor } from '@capacitor/core';
import { BhabiLink } from './bhabi-link';

export const FooterStyled = styled(Box, {
  shouldForwardProp: prop => prop !== 'isIOS',
})<{ isIOS: boolean }>(({ theme, isIOS }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: 'transparent',
  width: '100%',
  height: isIOS ? 55 : 40,
  color: alpha(theme.palette.text.primary, 0.5),
  gap: theme.spacing(1),
  '& .copy_right': {
    borderRight: ['1px', 'solid', alpha(theme.palette.text.primary, 0.5)].join(
      ' '
    ),
    paddingRight: theme.spacing(1),
  },
  '& a': {
    color: alpha(theme.palette.text.primary, 0.5),
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));

export const Footer: FC = () => (
  <FooterStyled isIOS={Capacitor.getPlatform() === 'ios'}>
    <Box className="copy_right" fontSize="small">
      Copyright &copy; {new Date().getFullYear()} Bhabi Inc.
    </Box>
    <BhabiLink
      to={TOC_ROUTES.ABSOLUTE_PATH}
      style={{
        textDecoration: 'none',
        fontSize: 'small',
        color: 'inherit',
      }}
    >
      Terms of service
    </BhabiLink>
  </FooterStyled>
);
