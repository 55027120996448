import { PlayerInfo } from '@/screens/authenticated/types';
import { Theme } from '@mui/material';
import { PlayerPosition } from './in-room-player-list';
import { MotionProps } from 'framer-motion';
import { remToPx } from '../../hooks';

export function findAvatarBgColor(
  playerID: string,
  nextTurn: string | null | undefined,
  avatarColor: string,
  theme: Theme,
  remainingCards: number
) {
  const color =
    nextTurn === playerID ? theme.palette.primary.main : avatarColor;

  return remainingCards > 0 ? color : 'text.disabled';
}

export function dividePlayers(players: PlayerInfo[]) {
  const totalPlayers = players.length;
  const firstCount = Math.round(totalPlayers * 0.5);
  const secondCount = Math.round(totalPlayers * 0.25);

  const firstPlayers = players.slice(0, firstCount);
  const secondPlayers = players.slice(firstCount, firstCount + secondCount);
  const thirdPlayers = players.slice(firstCount + secondCount);

  return [firstPlayers, thirdPlayers.reverse(), secondPlayers];
}

export function otherPlayerCardAnimation(
  id: string,
  playerPos: PlayerPosition[],
  isMd: boolean,
  placement?: 'left' | 'right'
) {
  const player = playerPos.find(player => player.id === id);
  if (!player) return undefined;

  const isHorizontal = player.isHorizontal;
  const pxY = isMd ? 6: 12;
  const px = isMd ? 10 : 15;
  const animateLeft = {
    x: remToPx(px),
    y: 0,
    rotate: 360,
  };

  const animateRight = {
    x: -remToPx(px),
    y: 0,
    rotate: -360,
  };

  const animateHorizontal = {
    x: 0,
    y: remToPx(pxY),
    rotate: 360,
  };

  let animate = {};
  if (placement === 'left') {
    animate = animateLeft;
  } else if (placement === 'right') {
    animate = animateRight;
  } else if (isHorizontal) {
    animate = animateHorizontal;
  }

  const motionProps: MotionProps = {
    style: { position: 'fixed', top: player.top, left: player.left },
    animate: {
      ...animate,
      transition: { duration: 0.6 },
    },
    initial: { y: 0, rotate: 0 },
  };

  return motionProps;
}
