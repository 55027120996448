import { Box } from '@mui/material';
import { styled } from '@mui/system';
import React, { FC, PropsWithChildren } from 'react';

export const CustomDividerWithText: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Container px={2}>
      <Box
        component="span"
        sx={{
          p: 0.5,
          color: 'text.secondary',
          position: 'relative',
          zIndex: 1,
        }}
      >
        {children}
      </Box>
    </Container>
  );
};

const Container = styled(Box)(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  color: theme.palette.text.secondary,
  '::before': {
    content: '""',
    flex: 1,
    borderBottom: '0.5px solid',
    marginRight: '.3em',
  },
  '::after': {
    content: '""',
    flex: 1,
    borderBottom: '0.5px solid',
    marginLeft: '.3em',
  },
}));
