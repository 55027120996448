import { configureStore } from '@reduxjs/toolkit';
import {
  AlertSliceState,
  ThemeSliceState,
  alertReducer,
  themeReducer,
  UserState,
  userReducer,
  InPlayState,
  inPlayReducer,
} from './reducers';

export type Store = {
  themeReducer: ThemeSliceState;
  alertReducer: AlertSliceState;
  userReducer: UserState;
  inPlayReducer: InPlayState;
};

export const store = configureStore<Store>({
  reducer: {
    themeReducer,
    alertReducer,
    userReducer,
    inPlayReducer,
  },
});

export const getStoreState = () => store.getState();
