import {
  Box,
  Paper,
  Stack,
  StackProps,
  styled,
  Theme,
  useMediaQuery,
} from '@mui/material';
import React, { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import { Texts } from '../../../shared/types';
import { BhabiLogo } from '../../../assets';

export const MainFrameWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  overflowY: 'hidden',
  height: '100vh',
  gap: theme.spacing(3),
  overflowX: 'hidden',
}));

const InnerWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  maxWidth: 550,
  minHeight: 400,
  padding: theme.spacing(2, 4),
  gap: theme.spacing(1),
  textAlign: 'center',
  boxShadow: theme.shadows[20],
}));

const LinkToOtherPage = styled(Link)(({ theme }) => ({
  color: theme.palette.secondary.main,
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'underline',
  },
}));

export type MainFrameProps = {
  stackProps?: StackProps;
  linkPath?: string;
  hideLink?: boolean;
  forgotPasswordPath?: string;
  texts?: Texts<'linkMessage' | 'forgotPassword'>;
  otherSignInOptions?: React.ReactNode;
};

export const MainFrame: FC<PropsWithChildren<MainFrameProps>> = ({
  children,
  stackProps,
  linkPath,
  hideLink = false,
  forgotPasswordPath,
  otherSignInOptions,
  texts = {
    linkMessage: 'Do not have an account? Sign up here',
    forgotPassword: 'Forgot password?',
  },
}) => {
  const isMobile = useMediaQuery<Theme>(theme => theme.breakpoints.down('md'));
  return (
    <MainFrameWrapper>
      <Box className="md:grid-cols-2 md:items-center md:justify-center md:grid">
        {!isMobile && (
          <Box className="flex justify-center items-center">
            <BhabiLogo style={{ height: 200, width: 200 }} />
          </Box>
        )}
        <InnerWrapper component={Paper}>
          {isMobile && (
            <Box className="flex justify-center items-center">
              <BhabiLogo style={{ height: 200, width: 200 }} />
            </Box>
          )}
          <Stack
            component="form"
            py={1}
            gap={1.5}
            alignItems="center"
            {...stackProps}
          >
            {children}
          </Stack>
          {otherSignInOptions}
          <Box className="flex flex-col gap-[0.3px] items-center">
            {hideLink ? null : (
              <LinkToOtherPage to={linkPath || '/'}>
                {texts.linkMessage}
              </LinkToOtherPage>
            )}
            {forgotPasswordPath && (
              <LinkToOtherPage to={forgotPasswordPath}>
                {texts.forgotPassword}
              </LinkToOtherPage>
            )}
          </Box>
        </InnerWrapper>
      </Box>
    </MainFrameWrapper>
  );
};
