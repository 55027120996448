import React, { useCallback } from 'react';
import {
  changeToBlob,
  mutateUploadFile,
  useFileDelete,
  useFileUpload,
} from '../../../shared/hooks';
import { useDispatch, useSelector } from 'react-redux';
import { Store } from '../../../redux';
import {
  Box,
  Button,
  LinearProgress,
  Paper,
  Stack,
  TextField,
} from '@mui/material';
import {
  ConfirmDeleteButton,
  UserAvatar,
  useAlert,
} from '../../../shared/components';
import { useForm } from 'react-hook-form';
import { LoggerService } from '../../../shared/logger';
import { updateUserState } from '../../../redux/reducers';

type Inputs = {
  file: FileList;
};

export const UploadProfilePicture = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Inputs>({
    mode: 'onSubmit',
  });
  const { mutate, isLoading, isError } = useFileUpload();
  const { photoURL, name } = useSelector((state: Store) => state.userReducer);
  const { addAlert } = useAlert();
  const dispatch = useDispatch();
  const { mutate: deleteProfilePic } = useFileDelete();

  const onClickDelete = useCallback(() => {
    deleteProfilePic(
      {},
      {
        onSuccess: () => {
          dispatch(updateUserState({ photoURL: null }));
          addAlert({
            message: 'Profile photo deleted',
            type: 'success',
          });
        },
        onError: err => {
          LoggerService.error('Error deleting profile photo: ', err);
          addAlert({
            message: 'Error deleting profile photo',
            type: 'error',
          });
        },
      }
    );
  }, [addAlert, deleteProfilePic, dispatch]);

  return (
    <Box pt={3} maxWidth={400} mx="auto" px={1} mb={1}>
      <Box
        component={Paper}
        {...{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          gap: 2,
          position: 'relative',
          p: 2,
        }}
      >
        <Box
          {...{
            position: 'absolute',
            top: 5,
            right: 5,
            zIndex: 100,
          }}
        >
          <ConfirmDeleteButton onClick={onClickDelete} />
        </Box>
        <UserAvatar
          hideState
          photoURL={photoURL}
          name={name}
          fetchCurrentUser
          avatarProps={{
            sx: {
              maxWidth: '100%',
              width: 150,
              height: 'auto',
              maxHeight: 200,
              alignSelf: 'center',
            },
          }}
          innerWrapperProps={{
            fontSize: 28,
          }}
        />
        <Stack
          component="form"
          display="grid"
          gridTemplateRows="auto"
          rowGap={2}
        >
          <TextField
            {...register('file', {
              required: 'Profile photo is required',
              validate: {
                checkFileType: value => {
                  const supportedFormats = [
                    'image/jpeg',
                    'image/jpg',
                    'image/png',
                  ];
                  return (
                    supportedFormats.includes(value[0].type) ||
                    'Only jpg, jpeg, and png formats are supported.'
                  );
                },
              },
            })}
            sx={{ width: '100%' }}
            placeholder="Photo"
            size="small"
            type="file"
            error={!!errors.file}
            helperText={errors.file?.message}
          />
          <Box>
            <Button
              onClick={handleSubmit(data => {
                mutateUploadFile(data, mutate, (res, err) => {
                  if (res) {
                    addAlert({
                      message: 'Profile photo updated',
                      type: 'success',
                    });

                    dispatch(
                      updateUserState({
                        photoURL: changeToBlob(res.file, res.mimeType),
                      })
                    );
                    reset();
                    return;
                  }

                  LoggerService.error('Profile photo update failed: ', err);

                  addAlert({
                    message: 'Profile photo update failed',
                    type: 'error',
                  });
                });
              })}
              size="small"
              variant="contained"
              fullWidth
              disabled={isLoading && !isError}
            >
              Upload
            </Button>
            {isLoading && (
              <LinearProgress sx={{ marginTop: 1 }} color="secondary" />
            )}
          </Box>
        </Stack>
      </Box>
    </Box>
  );
};
