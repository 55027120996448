import { Box, styled, Theme, useMediaQuery } from '@mui/material';
import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { CARD_HEIGHT, CARD_WIDTH, DeckCard } from '../deck-card';
import { Card, InPlayRoomWsResponse } from '../../../types';
import { ErrorLayout } from '../../../../../shared/components';

import { useSelector } from 'react-redux';
import { Store } from '../../../../../redux';
import { useThrowCard } from '../../context';
import { CardsRequestNotifier } from '../cards-request';
import { SendMessage } from 'react-use-websocket';

import { GroupChat } from '../group-chat';
import { usePlayground } from './hooks';
import {
  BottomLeftBox,
  InPlayCardsWrapper,
  PlaygroundWrapper,
  Thoka,
} from './styled';
import { RequestCardsButton } from './request-cards';
import { InRoomPlayerList, PlayerPosition } from './in-room-player-list';
import { soundEffect } from '../../../../../shared/lib';
import { dividePlayers, otherPlayerCardAnimation } from './utils';
import { cloneDeep } from 'lodash';

export interface PlaygroundProps {
  inPlayWS: InPlayRoomWsResponse;
  sendMessage: SendMessage;
}

export const Playground: FC<PropsWithChildren<PlaygroundProps>> = ({
  inPlayWS,
  sendMessage,
}) => {
  const inPlayCards = useMemo(
    () => inPlayWS?.gameInfo?.inPlayCards || [],
    [inPlayWS?.gameInfo?.inPlayCards]
  );

  const noOfPlayers = useMemo(
    () => inPlayWS?.gameInfo?.room?.numberOfPlayers || null,
    [inPlayWS?.gameInfo?.room?.numberOfPlayers]
  );

  const userID = useSelector((state: Store) => state.userReducer.id);

  // checking if there are other users in the room or there are all bots
  const isOtherUsers = useMemo(() => {
    const otherUser = inPlayWS?.players?.filter(
      player => player.id !== userID && player.userType !== 'bot'
    );

    return !!otherUser?.length;
  }, [inPlayWS?.players, userID]);

  const sortedPlayers = useMemo(
    () =>
      inPlayWS.players.length
        ? cloneDeep(inPlayWS.players).sort((a, b) => a.entryNo - b.entryNo)
        : [],
    [inPlayWS.players]
  );

  const [one, two, three] = useMemo(
    () => dividePlayers(sortedPlayers),
    [sortedPlayers]
  );

  const [playerPos, setPlayerPosition] = useState<PlayerPosition[]>([]);

  const lastNumberOfCards = useRef(0);
  const [lastCard, setLastCard] = useState<Card | null>(null);
  const [inPlayCardsClone, setInPlayCardsClone] = useState<Card[]>([]);
  const isMd = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));

  useEffect(() => {
    if (!inPlayCards.length) {
      lastNumberOfCards.current = 0;
      setLastCard(null);
      setInPlayCardsClone([]);
      return;
    }
    if (inPlayCards.length !== lastNumberOfCards.current) {
      lastNumberOfCards.current = inPlayCards.length;
      setLastCard(inPlayCards[inPlayCards.length - 1]);
    }
  }, [inPlayCards]);

  const findPlacement = () => {
    const player = playerPos.find(player => player.id === lastCard?.userID);
    if (player?.isHorizontal) {
      return undefined;
    }

    if (two.some(player => player.id === lastCard?.userID)) {
      return 'left';
    }

    return 'right';
  };

  useEffect(() => {
    if (lastCard) {
      setTimeout(() => {
        setLastCard(null);
        setInPlayCardsClone(inPlayCards);
      }, 600);
    }
  }, [lastCard, inPlayCards]);

  if (!noOfPlayers) {
    return (
      <ErrorLayout
        outerBoxProps={{
          height: {
            sm: `calc(100vh - ${CARD_HEIGHT.sm})`,
            md: `calc(100vh - ${CARD_HEIGHT.md})`,
          },
        }}
      />
    );
  }

  return (
    <PlaygroundWrapper className="container mx-auto mt-[-20px]">
      {lastCard && lastCard.userID !== userID && (
        <DeckCard
          card={lastCard}
          motionDivProps={otherPlayerCardAnimation(
            lastCard.userID,
            playerPos.filter(player => player.id !== userID),
            isMd,
            findPlacement()
          )}
        />
      )}
      {inPlayWS?.gameInfo?.room?.roomID.length && isOtherUsers && (
        <Box
          sx={theme => ({
            position: 'absolute',
            bottom: theme.spacing(3),
            right: theme.spacing(2),
            zIndex: 2300,
          })}
        >
          <GroupChat roomID={inPlayWS?.gameInfo?.room?.roomID} />
        </Box>
      )}
      {inPlayWS?.showRequestCardsOption && (
        <BottomLeftBox>
          <RequestCardsButton {...{ sendMessage }} />
        </BottomLeftBox>
      )}
      {inPlayWS?.gameInfo?.room?.cardsRequestFrom?.length && (
        <BottomLeftBox>
          <CardsRequestNotifier
            requestUsers={inPlayWS?.gameInfo?.room?.cardsRequestFrom}
            sendMessage={sendMessage}
          />
        </BottomLeftBox>
      )}
      <div className="flex flex-col justify-center w-full">
        <div className="flex items-center justify-center sm:py-1 lg:py-3">
          <InRoomPlayerList
            setPlayerPosition={setPlayerPosition}
            nextTurn={inPlayWS.gameInfo.room?.nextTurn ?? ''}
            noOfPlayers={noOfPlayers}
            players={one}
            isHorizontal
          />
        </div>
        <div className="grid grid-cols-[auto_1fr_auto] gap-2">
          <InRoomPlayerList
            setPlayerPosition={setPlayerPosition}
            nextTurn={inPlayWS.gameInfo.room?.nextTurn ?? ''}
            noOfPlayers={noOfPlayers}
            players={two}
          />
          <RoundedTable>
            <InPlayCardsWithLayout
              cards={
                lastCard?.userID !== userID ? inPlayCardsClone : inPlayCards
              }
              concludedCards={inPlayWS?.concludedCards}
            />
          </RoundedTable>
          <InRoomPlayerList
            setPlayerPosition={setPlayerPosition}
            nextTurn={inPlayWS.gameInfo.room?.nextTurn ?? ''}
            noOfPlayers={noOfPlayers}
            players={three}
          />
        </div>
      </div>
    </PlaygroundWrapper>
  );
};

export interface InPlayCardsWithLayoutProps {
  cards: Card[];
  concludedCards: number;
}

export const InPlayCardsWithLayout: FC<InPlayCardsWithLayoutProps> = ({
  cards,
}) => {
  const { gridWidth, setGridWidth } = useThrowCard();
  const { turnRes } = usePlayground(setGridWidth);
  const md = useMediaQuery<Theme>(theme => theme.breakpoints.down('lg'));

  useEffect(() => {
    setGridWidth(md ? CARD_WIDTH.md / (cards.length + 1) : CARD_WIDTH.sm * 1.6);
  }, [md, cards.length, setGridWidth]);

  const lastCardsLength = useRef(0);

  useEffect(() => {
    if (!cards.length) {
      lastCardsLength.current = 0;
      return;
    }

    if (lastCardsLength.current < cards.length && !turnRes?.thoka) {
      lastCardsLength.current = cards.length;
      soundEffect.playSound(soundEffect.punch);
    }
  }, [cards.length, turnRes?.thoka]);

  return (
    <InPlayCardsWrapper
      px={1}
      numberOfPlayers={cards.length + 1}
      gridWidth={gridWidth}
    >
      {turnRes?.thoka && <Thoka />}
      <Box className="inner_box">
        {/* <ConcludedCardsDeck {...{ concludedCards }} /> */}
        {cards.map((card, i) => (
          <DeckCard card={card} key={i} />
        ))}
      </Box>
    </InPlayCardsWrapper>
  );
};

const RoundedTable = styled(Box)(({ theme }) => ({
  borderRadius: '100px',
  border: `8px solid ${theme.palette.grey[800]}`,
  backgroundColor: theme.palette.secondary.main,
  display: 'flex',
  justifyContent: 'center',
  height: '45vh',
  [theme.breakpoints.down('lg')]: {
    height: '45vh',
  },
}));
