export const PROFILE_ROUTE = {
  TITLE: 'Profile',
  ABSOLUTE_PATH: '/profile',
  EXACT_PATH: '/profile',
};

export const PUBLIC_PROFILE_ROUTE = {
  TITLE: 'Public Profile',
  ABSOLUTE_PATH: '/public-profile',
  KEY: 'public-profile',
  EXACT_PATH: [PROFILE_ROUTE.ABSOLUTE_PATH, '/public-profile'].join(''),
};

export const UPLOAD_PROFILE_PHOTO_ROUTE = {
  TITLE: 'Upload Profile Photo',
  ABSOLUTE_PATH: '/upload-profile-photo',
  KEY: 'upload-profile-photo',
  EXACT_PATH: [PROFILE_ROUTE.ABSOLUTE_PATH, '/upload-profile-photo'].join(''),
};
