import { Button, TextField, Typography } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { SIGN_IN_ROUTES } from './route.definitions';
import { SubmitHandler, useForm } from 'react-hook-form';
import { EMAIL_PATTERN } from '../../../shared/consts';
import { PasswordInput, useAlert } from '../../../shared/components';
import { MainFrame } from '../components/main-frame';
import { firebaseAuth } from '../../../shared/lib';
import { useSelector } from 'react-redux';
import { Store } from '../../../redux';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { UserCredential, signInWithEmailAndPassword } from 'firebase/auth';
import { FirebaseError } from 'firebase/app';
import { LoggerService } from '../../../shared/logger';
import { CustomDividerWithText } from '../components/custom-divider';
import { ContinueWithGoogleButton } from '../components/continue-with-google-button';
import { ContinueWithAppleButton } from '../components/continue-with-apple-button';
import { Capacitor } from '@capacitor/core';
import { HOME_ROUTE } from '../../authenticated/home';
import { IN_PLAY_ROUTE } from '../../authenticated/in-play';
import { DownloadLink } from './components';

export declare type SignInInput = {
  email: string;
  password: string;
};

export interface SignInPageProps {
  texts?: Record<string, string>;
}

export const useSignIn = () =>
  useMutation<UserCredential, FirebaseError, SignInInput>({
    mutationKey: 'sign-in',
    mutationFn: (data: SignInInput) => {
      return signInWithEmailAndPassword(
        firebaseAuth,
        data.email,
        data.password
      );
    },
  });

export const SignInPage: FC<SignInPageProps> = ({
  texts = {
    signInTitle: SIGN_IN_ROUTES.TITLE,
    button: 'Sign In',
  },
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm<SignInInput>({
    mode: 'onSubmit',
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const { mutate: signIn } = useSignIn();

  const { addAlert } = useAlert();

  const { user } = useSelector((state: Store) => ({
    user: state.userReducer,
  }));

  const nav = useNavigate();

  useEffect(() => {
    if (!user.activatedAt.Valid && user.isAuthenticated) {
      nav('/verify-email');
      return;
    }

    if (user.isAuthenticated && user.isInRoom) {
      nav(IN_PLAY_ROUTE.ABSOLUTE_PATH);
      return;
    }

    if (user.isAuthenticated) {
      nav(HOME_ROUTE.ABSOLUTE_PATH);
      return;
    }
  }, [nav, user]);

  const onSubmit: SubmitHandler<SignInInput> = data => {
    signIn(
      { email: data.email.trim().toLowerCase(), password: data.password },
      {
        onError: err => {
          LoggerService.error(err);
          addAlert({
            type: 'error',
            message: 'Failed to Sign In. Invalid email or password.',
          });
        },
        onSuccess: () => {
          reset();
        },
      }
    );
  };

  return (
    <>
      <DownloadLink />
      <MainFrame
        stackProps={{ onSubmit: handleSubmit(onSubmit) }}
        linkPath="/sign-up"
        forgotPasswordPath="/reset-password"
        otherSignInOptions={
          <>
            <CustomDividerWithText>OR</CustomDividerWithText>
            <ContinueWithGoogleButton />
            {Capacitor.getPlatform() === 'ios' && <ContinueWithAppleButton />}
          </>
        }
      >
        <Typography fontWeight={800} fontSize={18}>
          {texts.signInTitle}
        </Typography>
        <TextField
          {...register('email', {
            required: `Email is required.`,
            pattern: EMAIL_PATTERN,
          })}
          placeholder="Email"
          error={!!errors.email}
          helperText={<>{errors.email?.message}</>}
        />
        <PasswordInput<SignInInput>
          controllerProps={{
            control,
            name: 'password',
            rules: {
              required: `Password is required.`,
            },
          }}
          textFieldProps={{
            placeholder: 'Password',
            error: !!errors.password,
            helperText: <>{errors.password?.message}</>,
          }}
        />

        <Button type="submit" variant="contained">
          {texts.button}
        </Button>
      </MainFrame>
    </>
  );
};
