import { AxiosError } from 'axios';
import React, { FC, ReactNode } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      onError: error => {
        if (error instanceof AxiosError && error.response?.status === 401) {
          window.location.assign('/');
        }
      },
    },
  },
});

export interface PMQueryProviderProps {
  children: ReactNode;
}

export const PMQueryProvider: FC<PMQueryProviderProps> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>{children}</QueryClientProvider>
  );
};
