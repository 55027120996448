import { useTheme } from '@mui/material';
import React, { FC, PropsWithChildren } from 'react';
import { Link, LinkProps } from 'react-router-dom';

export const BhabiLink: FC<PropsWithChildren<LinkProps>> = ({
  children,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Link
      {...props}
      style={{ color: theme.palette.primary.main, ...props.style }}
    >
      {children}
    </Link>
  );
};
