import { useEffect, useRef, useState } from 'react';
import { GenerateAvatarColor } from '../utils';
import { PaletteMode, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { useThokaBazzi } from '../../hooks';
import { Store } from '../../../../../redux';
import { LastTurnResult } from '../../../types';
import { useThrowCard } from '../../context';
import { useLeaveRoom } from '../../../components';
import { soundEffect } from '../../../../../shared/lib';

export const useRandomColor = (noOfPlayers: number | null) => {
  const {
    palette: { mode },
  } = useTheme();

  const isColorSet = useRef<PaletteMode>();
  const [avatarColors, setAvatarColors] = useState<string[]>([]);

  useEffect(() => {
    if ((isColorSet.current === mode && avatarColors.length) || !noOfPlayers) {
      return;
    }

    const av = new GenerateAvatarColor(mode, noOfPlayers);
    setAvatarColors(av.avatarColors);
    isColorSet.current = mode;
  }, [avatarColors.length, mode, noOfPlayers]);

  return avatarColors;
};

export const usePlayground = (
  setGridWidth: React.Dispatch<React.SetStateAction<number>>
) => {
  const { gameInfo, id: userID } = useSelector((state: Store) => ({
    ...state.inPlayReducer,
    ...state.userReducer,
  }));

  const { afterThrowCard, ...options } = useThokaBazzi(setGridWidth);

  const { userCallBazzi, turnRes } = JSON.parse(
    gameInfo?.room?.lastTurnResult || '{}'
  ) as LastTurnResult;

  const { setGameState } = useThrowCard();
  const onClickLeave = useLeaveRoom();

  const isGameOver = useRef(false);

  useEffect(() => {
    const thokaSound = async () => {
      await soundEffect.playSound(soundEffect.thoka);
    };

    if (turnRes?.thoka) {
      thokaSound();

      return;
    }
  }, [turnRes?.bazzi, turnRes?.thoka]);

  useEffect(() => {
    if (turnRes?.userLost?.length && !isGameOver.current) {
      onClickLeave(`${turnRes.userLost} is Bhabi!`)();
      isGameOver.current = true;
    }
  }, [turnRes?.userLost, onClickLeave]);

  useEffect(() => {
    if (
      (!turnRes?.bazzi && !turnRes?.thoka) ||
      !gameInfo?.inPlayCards?.length
    ) {
      return;
    }

    if (userID === userCallBazzi && turnRes) {
      afterThrowCard(turnRes, isSuc => {
        if (isSuc) {
          setGameState('clear');
        }
      });
      return;
    }

    (async function () {
      if (turnRes?.bazzi || turnRes?.thoka) {
        await options.decreaseGridWidthGradually();
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gameInfo?.inPlayCards?.length]);

  return { options, turnRes };
};
