import { useState } from 'react';
import { SearchUserInput } from './components';
import { api } from '../../../api';
import { Store } from '../../../redux';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { API_URLS } from '../consts';

export const useSearchUser = (
  defaultURL = `${API_URLS.SEARCH_USERS}?search=@`,
  extraParams?: Record<string, string>
) => {
  const { token } = useSelector((state: Store) => state.userReducer);

  const [noUserFound, setNoUserFound] = useState(false);

  const [searchUserURL, setSearchUserURL] = useState(defaultURL);

  const { data: { data: userInfo } = {}, ...remaining } = useQuery<{
    data: SearchUserInput[];
  }>({
    queryKey: ['search-user', searchUserURL],
    queryFn: async () => {
      const res = await api<{ data: SearchUserInput[] }>({
        method: 'GET',
        url: searchUserURL,
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });

      setNoUserFound(!res.data?.data?.length);

      return res.data;
    },
  });

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const searchParams = new URLSearchParams();

    if (e.target.value.length === 0) {
      searchParams.append('search', '@');
    } else {
      searchParams.append('search', e.target.value.toLowerCase());
    }

    if (extraParams) {
      Object.entries(extraParams).forEach(([key, value]) => {
        searchParams.append(key, value);
      });
    }

    const searchUserURL = [
      API_URLS.SEARCH_USERS,
      '?',
      searchParams.toString(),
    ].join('');

    setSearchUserURL(searchUserURL);
  };

  return {
    onChange,
    userInfo,
    noUserFound,
    ...remaining,
  };
};
