import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { logoutUser } from '../../../redux/reducers';
import { BhabiStorage, firebaseAuth } from '../../../shared/lib';
import { useNavigate } from 'react-router-dom';
import { Capacitor } from '@capacitor/core';
import { FirebaseAuthentication } from '@capacitor-firebase/authentication';
import { SIGN_IN_ROUTES } from '../../unauthenticated';

export const useLogout = () => {
  const dispatch = useDispatch();
  const nav = useNavigate();

  const logout = useCallback(() => {
    (async () => {
      await BhabiStorage.removeItem('token');
      await BhabiStorage.removeItem('signedUpFor');
      dispatch(logoutUser({}));
      nav(SIGN_IN_ROUTES.ABSOLUTE_PATH);
      if (Capacitor.getPlatform() === 'ios') {
        await FirebaseAuthentication.signOut();
      }
      await firebaseAuth.signOut();
    })();
  }, [dispatch, nav]);

  return {
    logout,
  };
};
