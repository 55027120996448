import { isBoolean, isUndefined, noop } from 'lodash';
import { ENV } from './env';

export type LogLevel = 'debug' | 'verbose' | 'info' | 'warn' | 'error';

type Console = Pick<
  typeof console,
  Exclude<LogLevel, 'verbose'> | 'log' | 'group' | 'groupEnd'
>;

export class LoggerService {
  private static readonly LOG_LEVEL: LogLevel = ENV.LOG_LEVEL;

  private static readonly LOG_LEVELS: { [level in LogLevel]: number } = {
    debug: 0,
    verbose: 1,
    info: 2,
    warn: 3,
    error: 4,
  };

  private static get LOG_LEVEL_WEIGHT(): number {
    try {
      const weight = LoggerService.LOG_LEVELS[LoggerService.LOG_LEVEL];

      if (isUndefined(weight)) {
        throw new Error('LOG_LEVEL_WEIGHT is undefined');
      }

      return weight;
    } catch {
      return LoggerService.LOG_LEVELS.error;
    }
  }

  private static readonly PREFIX = '[BHABI]:';

  private static NOOP_CONSOLE: Console = {
    info: noop,
    error: noop,
    debug: noop,
    warn: noop,
    log: noop,
    group: noop,
    groupEnd: noop,
  };

  private static logger(shouldLog: boolean | null) {
    return isBoolean(shouldLog) && shouldLog
      ? (console as Console)
      : LoggerService.NOOP_CONSOLE;
  }

  /* eslint-disable @typescript-eslint/no-explicit-any */
  public static debug = (...args: any[]) => {
    LoggerService.logger(
      LoggerService.LOG_LEVELS.debug >= LoggerService.LOG_LEVEL_WEIGHT
    ).debug(LoggerService.valuesToString(...args));
  };

  public static error = (...args: any[]) => {
    LoggerService.logger(
      LoggerService.LOG_LEVELS.error >= LoggerService.LOG_LEVEL_WEIGHT
    ).error(LoggerService.valuesToString(...args));
  };

  public static warn = (...args: any[]) => {
    LoggerService.logger(
      LoggerService.LOG_LEVELS.warn >= LoggerService.LOG_LEVEL_WEIGHT
    ).warn(LoggerService.valuesToString(...args));
  };

  public static info = (...args: any[]) => {
    LoggerService.logger(
      LoggerService.LOG_LEVELS.info >= LoggerService.LOG_LEVEL_WEIGHT
    ).info(LoggerService.valuesToString(...args));
  };

  public static verbose = (...args: any[]) => {
    LoggerService.logger(
      LoggerService.LOG_LEVELS.verbose >= LoggerService.LOG_LEVEL_WEIGHT
    ).log(LoggerService.valuesToString(...args));
  };

  public static group = (logLevel: LogLevel, ...args: any[]) => {
    LoggerService.logger(
      LoggerService.LOG_LEVELS[logLevel] >= LoggerService.LOG_LEVEL_WEIGHT
    ).group(LoggerService.valuesToString(...args));
  };

  public static groupEnd = (logLevel: LogLevel) => {
    LoggerService.logger(
      LoggerService.LOG_LEVELS[logLevel] >= LoggerService.LOG_LEVEL_WEIGHT
    ).groupEnd();
  };
  /* eslint-enable @typescript-eslint/no-explicit-any */

  /* eslint-disable @typescript-eslint/no-explicit-any */
  private static readonly valuesToString = (...args: any[]): string =>
    [LoggerService.PREFIX, ...args].map(LoggerService.valueToString).join(' ');
  /* eslint-enable @typescript-eslint/no-explicit-any */

  private static readonly valueToString = <V>(value: V): string => {
    if (typeof value === 'string') {
      return value;
    }

    if (Array.isArray(value)) {
      return value.map(LoggerService.valueToString).join(' ');
    }

    if (value instanceof Error) {
      return value.message;
    }

    try {
      return JSON.stringify(value);
    } catch {
      return String(value);
    }
  };
}
