import { Box, BoxProps, Typography, styled } from '@mui/material';
import React, { FC, PropsWithChildren } from 'react';
import { motion } from 'framer-motion';

import { CARD_HEIGHT, CARD_WIDTH } from '../deck-card';
import { BhabiLogo } from '../../../../../assets';
import { blueGrey, grey } from '@mui/material/colors';

export const Thoka: FC = () => (
  <Box
    {...{
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 3500,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontSize: '3rem',
      fontWeight: 700,
      color: 'primary.main',
    }}
  >
    <motion.div
      initial={{ scale: 0.5, opacity: 0.5 }}
      animate={{ scale: [1, 2, 1], opacity: [1, 0.5, 1] }}
      transition={{
        duration: 2,
        ease: 'easeInOut',
        times: [0, 0.5, 1],
        loop: Infinity,
        repeatDelay: 1,
      }}
    >
      Thoka
    </motion.div>
  </Box>
);

export const LogoCard: FC<PropsWithChildren<BoxProps>> = ({
  children,
  ...props
}) => {
  return (
    <Box
      {...{
        height: CARD_HEIGHT,
        width: CARD_WIDTH,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        bgcolor: theme =>
          theme.palette.mode === 'dark' ? blueGrey[900] : grey[500],
        boxShadow: theme => theme.shadows[6],
        sx: {
          borderRadius: '8px',
        },
        ...props,
      }}
    >
      <BhabiLogo
        style={{
          width: 50,
          height: 50,
        }}
      />
      {children}
    </Box>
  );
};

export interface ConcludedCardsDeckProps {
  concludedCards: number;
}

export const ConcludedCardsDeck: FC<ConcludedCardsDeckProps> = ({
  concludedCards,
}) => {
  return (
    <LogoCard>
      <Typography
        fontSize={{
          lg: '2rem',
          sm: '1.5rem',
        }}
        fontWeight={700}
        sx={{ color: theme => theme.palette.success.dark }}
      >
        {concludedCards}
      </Typography>
    </LogoCard>
  );
};

interface PlayerAvatarWrapperProps {
  isHorizontal?: boolean;
}
export const PlayersAvatarWrapper = styled(Box, {
  shouldForwardProp: props => props !== 'isHorizontal',
})<PlayerAvatarWrapperProps>(({ isHorizontal, theme }) => ({
  ...(isHorizontal
    ? {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-evenly',
        alignItems: 'center',
      }
    : {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: theme.spacing(3),
      }),
}));

export const PlaygroundWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
  height: `calc(100vh - ${CARD_HEIGHT})`,
  padding: theme.spacing(0.5, 2),
  paddingTop: theme.spacing(2),
}));

export interface InPlayCardsWrapperProps {
  gridWidth: number;
  numberOfPlayers: number;
}

export const InPlayCardsWrapper = styled(Box, {
  shouldForwardProp: props =>
    props !== 'gridWidth' && props !== 'numberOfPlayers',
})<InPlayCardsWrapperProps>(({ theme, gridWidth, numberOfPlayers }) => ({
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  width: '100%',
  '& .inner_box': {
    position: 'relative',
    width: '80%',
    margin: '0 auto',
    display: 'grid',
    gridTemplateColumns: `repeat(${numberOfPlayers}, ${gridWidth}px)`,
    gap: theme.spacing(0.5),
  },
}));

export const BottomLeftBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(2),
  left: theme.spacing(2),
  zIndex: 300,
}));
