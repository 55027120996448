import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { FC } from 'react';

export const ClubsIcon: FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 512 512" {...props}>
    {clubsIcon}
  </SvgIcon>
);

const clubsIcon = (
  <path
    fill="#000000"
    d="M477.443 295.143a104.45 104.45 0 0 1-202.26 36.67c-.08 68.73 4.33 114.46 69.55 149h-177.57c65.22-34.53 69.63-80.25 69.55-149a104.41 104.41 0 1 1-66.34-136.28 104.45 104.45 0 1 1 171.14 0 104.5 104.5 0 0 1 135.93 99.61z"
  />
);
