import CloseIcon from '@mui/icons-material/Close';

import { Box, Paper, Typography, styled } from '@mui/material';
import React, { FC, useEffect } from 'react';
import { AppleIcon, BhabiLogo, PlayStoreIcon } from '../../../../assets';
import { Capacitor } from '@capacitor/core';

const openDownloadLink = (
  url = 'https://apps.apple.com/ca/app/bhabi/id6475659322'
) => {
  window.open(url, '_blank');
};

export const DownloadLink: FC = () => {
  const [hide, setHide] = React.useState(true);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      setHide(true);
    } else {
      setHide(false);
    }
  }, []);

  return (
    <>
      {!hide && (
        <Box
          {...{
            position: 'fixed',
            top: 2,
            left: 0,
            width: '100%',
          }}
        >
          <DownloadLinkWrapper component={Paper} position="relative">
            <Box
              {...{
                position: 'absolute',
                top: 5,
                right: 5,
              }}
            >
              <Typography onClick={() => setHide(true)}>
                <CloseIcon />
              </Typography>
            </Box>
            <DownloadLinkInnerWrapper>
              <Box
                component={Paper}
                sx={{
                  height: 110,
                  width: 110,
                }}
                {...{
                  justifyContent: 'center',
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <BhabiLogo style={{ height: 80, width: 100 }} />
              </Box>
            </DownloadLinkInnerWrapper>
            <DownloadLinkInnerWrapper gap={1}>
              <Typography
                variant="body1"
                sx={{
                  display: 'flex',
                  gap: 1,
                  alignItems: 'center',
                  textAlign: 'left',
                  fontSize: '18px',
                  fontWeight: 500,
                }}
              >
                Download from App Store
              </Typography>
              <Typography className="flex items-center gap-6">
                <AppleIcon
                  className="cursor-pointer"
                  onClick={() => openDownloadLink()}
                />
                <PlayStoreIcon
                  className="cursor-pointer"
                  onClick={() =>
                    openDownloadLink(
                      'https://play.google.com/store/apps/details?id=com.bhabi'
                    )
                  }
                />
              </Typography>
            </DownloadLinkInnerWrapper>
          </DownloadLinkWrapper>
        </Box>
      )}
    </>
  );
};

export const DownloadLinkWrapper = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  maxWidth: 400,
  margin: '0 auto',
  backgroundColor: theme.palette.grey[800],
  color: theme.palette.secondary.contrastText,
}));

export const DownloadLinkInnerWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(1),
  minHeight: 110,
}));
