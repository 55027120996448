import React, { FC, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  BhabiLink,
  ErrorLayout,
  LoadingLayout,
  useAlert,
} from '../../../../shared/components';
import {
  Box,
  Divider,
  Paper,
  Rating,
  RatingProps,
  Typography,
  styled,
} from '@mui/material';
import { useHttpMutation, useHttpQuery } from '../../../../api';
import { API_URLS } from '../../consts';
import { RateUserInput, UserPublicProfileResponse } from '../../types';
import { LoggerService } from '../../../../shared/logger';
import { CHAT_PAGE_ROUTE } from '../../chat';
import { MessageIcon } from '../../../../assets';
import { useSelector } from 'react-redux';
import { Store } from '../../../../redux';

export const PublicProfilePage: FC<{ id?: string }> = ({ id }) => {
  const [params] = useSearchParams();
  const userID = useMemo(() => id || params.get('user_id'), [params, id]);

  const [userProfileURL, setUserProfileURL] = useState<string>('');
  const currentUser = useSelector((state: Store) => state.userReducer);

  useEffect(() => {
    if (userID?.length || !userProfileURL.length) {
      setUserProfileURL([API_URLS.GET_USER_PUBLIC_PROFILE, userID].join('/'));
    }
  }, [userProfileURL, userID]);

  const {
    data: user,
    isLoading,
    isError,
    refetch,
  } = useHttpQuery<UserPublicProfileResponse>(
    ['userPublicProfile'],
    userProfileURL,
    'GET',
    undefined,
    {
      enabled: !!userProfileURL?.length,
    }
  );

  const [rating, setRating] = useState<number | null>(0);
  const { mutate: rateUser } = useHttpMutation<
    { message: string },
    RateUserInput
  >(API_URLS.RATE_USER, 'POST');

  const { addAlert } = useAlert();

  const onChangeRating: RatingProps['onChange'] = (_, newValue) => {
    if (!userID || !newValue) {
      return;
    }

    setRating(newValue);

    rateUser(
      {
        userID,
        rating: newValue,
      },
      {
        onSuccess: () => {
          refetch();

          addAlert({
            type: 'success',
            message: `${user?.name || 'user'} is rated ${newValue} stars`,
          });
        },
        onError: err => {
          LoggerService.error('Rate user failed', err);
          addAlert({
            type: 'error',
            message: 'Rate user failed',
          });
        },
      }
    );
  };

  if (!userID) {
    return (
      <ErrorLayout
        texts={{
          defaultError: 'User ID not found',
        }}
      />
    );
  }

  if (isLoading && !isError) {
    return <LoadingLayout height="auto" />;
  }

  if (isError) {
    return (
      <ErrorLayout
        outerBoxProps={{
          height: 'auto',
        }}
      />
    );
  }

  return (
    <ProfilePageStyled>
      <Box
        {...{
          display: 'grid',
          gridTemplateRows: 'auto',
          rowGap: 1,
          maxWidth: {
            lg: '65%',
            md: '75%',
            sm: '100%',
          },
          margin: 'auto',
        }}
      >
        <Box pb={1}>
          <Box display="flex" justifyContent="space-between">
            <Box>
              <Typography variant="h6" color="primary">
                {user?.name}
              </Typography>
              <Typography variant="body1" color="textSecondary">
                {user?.email}
              </Typography>
            </Box>
            {currentUser?.firebaseUID !== user?.firebaseUID && (
              <BhabiLink
                to={[
                  CHAT_PAGE_ROUTE.ABSOLUTE_PATH,
                  '?receiverID=',
                  user?.firebaseUID,
                ].join('')}
              >
                <MessageIcon
                  fontSize="large"
                  sx={{ color: theme => theme.palette.primary.main }}
                />
              </BhabiLink>
            )}
          </Box>
          <Divider />
        </Box>
        <InfoRow>
          <InfoBox component={Paper}>
            <Typography variant="body1">Games Played</Typography>
            <Typography
              sx={{
                fontSize: 45,
                fontWeight: 900,
              }}
              color="primary"
            >
              {user?.games}
            </Typography>
          </InfoBox>
          <InfoBox component={Paper}>
            <Typography variant="body1">Wins</Typography>
            <Typography
              sx={{
                fontSize: 45,
                fontWeight: 900,
                color: theme => theme.palette.success.main,
              }}
            >
              {user?.wins}
            </Typography>
          </InfoBox>
        </InfoRow>

        <InfoRow>
          <InfoBox component={Paper}>
            <Typography variant="body1">Losses</Typography>
            <Typography
              sx={{
                fontSize: 45,
                fontWeight: 900,
                color: theme => theme.palette.error.main,
              }}
            >
              {user?.lost}
            </Typography>
          </InfoBox>
          <InfoBox
            component={Paper}
            sx={{ gap: theme => theme.spacing(!id ? 1 : 6) }}
          >
            {!id ? (
              <>
                <Typography>{`Rate ${user?.name}`}</Typography>
                <Rating
                  name="simple-controlled"
                  value={rating}
                  onChange={onChangeRating}
                />

                <Typography variant="body1">Average Rating</Typography>
                <Typography
                  sx={{
                    fontSize: 50,
                    fontWeight: 900,
                    color: theme => theme.palette.warning.main,
                  }}
                >
                  {user?.rating}
                </Typography>
              </>
            ) : (
              <>
                <Typography>Rating</Typography>
                <Rating name="read-only" value={user?.rating || 0} readOnly />
              </>
            )}
          </InfoBox>
        </InfoRow>
      </Box>
    </ProfilePageStyled>
  );
};

export const ProfilePageStyled = styled(Box)(({ theme }) => ({
  padding: theme.spacing(1),
  overflow: 'hidden',
  width: '100%',
}));

const InfoRow = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: theme.spacing(0.5),
}));

const InfoBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '1rem',
  padding: theme.spacing(1),
  boxShadow: theme.shadows[1],
  minHeight: '22vh',
}));
