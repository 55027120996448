import { Preferences } from '@capacitor/preferences';
import { LoggerService } from '../logger';
import { noop } from 'lodash';

export class BhabiStorage {
  static async setToken(token: string) {
    try {
      await Preferences.set({
        key: 'token',
        value: token,
      });
    } catch (e) {
      LoggerService.error(e);
    }
  }

  static async getItem(
    callback: (res: string | null, err?: boolean) => void,
    key: string = 'token'
  ) {
    try {
      const ret = await Preferences.get({ key });
      if (!ret.value) {
        callback(null, true);
        return;
      }
      callback(ret.value, false);

      return ret.value;
    } catch (e) {
      LoggerService.error(e);
      callback(null, true);
    }
  }

  static async setItem(key: string, value: string, callback = noop) {
    try {
      await Preferences.set({
        key,
        value,
      });
      callback(true);
    } catch (e) {
      LoggerService.error('error setting item', key, { err: e });
      callback(false);
    }
  }

  static async removeItem(key: string) {
    try {
      await Preferences.remove({
        key,
      });
    } catch (e) {
      LoggerService.error('error removing item', key, { err: e });
    }
  }
}
