import React, { FC, PropsWithChildren, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Box } from '@mui/material';
import { IN_PLAY_ROUTE } from '../in-play';
import { Footer } from '../../../shared/components/footer';
import { PrimaryTopBar } from './top-bar';
import {
  SendWSMessageProvider,
  useSendSocketMessage,
} from '../send-message-ctx';
import { noop } from 'lodash';
import { CHAT_PAGE_ROUTE, DM_PAGE_ROUTE } from '../chat';
import { adMob } from '@/shared/lib';

export const BhabiAppLayoutWithoutCtx: FC<PropsWithChildren> = ({
  children,
}) => {
  const { sendMessage } = useSendSocketMessage();
  const { pathname } = useLocation();
  const [showingAd, setShowingAd] = useState(false);

  useEffect(() => {
    if (pathname === IN_PLAY_ROUTE.ABSOLUTE_PATH) {
      adMob.removeBanner().then(() => {
        setShowingAd(false);
      });
      return;
    }
    if (!showingAd) {
      adMob.banner().then(() => {
        setShowingAd(true);
      });
    }
  }, [pathname, showingAd]);

  return (
    <PrimaryTopBar sendMessage={sendMessage || noop}>
      {() => (
        <Box
          display="grid"
          gridTemplateRows="auto"
          rowGap={1}
          overflow="hidden"
          pt={5}
        >
          {children}
          {pathname !== IN_PLAY_ROUTE.ABSOLUTE_PATH &&
          pathname !== CHAT_PAGE_ROUTE.ABSOLUTE_PATH &&
          pathname !== DM_PAGE_ROUTE.ABSOLUTE_PATH ? (
            <Footer />
          ) : null}
        </Box>
      )}
    </PrimaryTopBar>
  );
};

export const BhabiAppLayout: FC<PropsWithChildren> = ({ children }) => {
  return (
    <SendWSMessageProvider>
      <BhabiAppLayoutWithoutCtx>{children}</BhabiAppLayoutWithoutCtx>
    </SendWSMessageProvider>
  );
};
