import { useDispatch } from 'react-redux';
import { changeTheme } from '../redux/reducers';
// import { useEffect } from 'react';
import { BhabiStorage } from '../shared/lib';

export const useToggleTheme = () => {
  const dispatch = useDispatch();

  const toggle = (mode: 'light' | 'dark') => {
    BhabiStorage.setItem('mode', mode, added => {
      if (!added) {
        return;
      }
      dispatch(changeTheme(mode));
    });
  };

  return toggle;
};
