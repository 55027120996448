import { Box, styled, useTheme } from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { useToggleTheme } from '../../../theme/hooks';

export const Toc: FC = () => {
  const toggle = useToggleTheme();
  const [isToggled, setIsToggled] = useState(false);

  const {
    palette: { mode },
  } = useTheme();

  useEffect(() => {
    if (mode !== 'light' && !isToggled) {
      toggle('light');
      setIsToggled(true);
    }
  }, [toggle, isToggled, mode]);

  useEffect(
    () => () => {
      if (isToggled && mode !== 'dark') {
        toggle('dark');
      }
    },
    [isToggled, mode, toggle]
  );

  return (
    <Box
      sx={{
        overflow: 'hidden',
        height: 'calc(100vh - 75px)',
        backgroundColor: '#fff',
      }}
      pt={9}
      mt="env(safe-area-inset-top)"
    >
      <IFrameToc src="/toc.html" />
    </Box>
  );
};

const IFrameToc = styled('iframe')(({ theme }) => ({
  padding: theme.spacing(1),
  border: 'none',
  width: '100%',
  height: '100%',
  minHeight: '100vh',
}));
