import React, { FC } from 'react';
import { CircularProgress, Box, Typography, BoxProps } from '@mui/material';
import { BhabiLogo } from '../../assets';

interface LoadingProps {
  text?: string;
}

export const LoadingLayout: FC<LoadingProps & BoxProps> = ({
  text = '',
  ...props
}) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      gap={2}
      {...props}
    >
      <BhabiLogo style={{ height: 200, width: 200 }} />
      <CircularProgress color="primary" size={50} />
      <Typography variant="body1" color="textSecondary">
        {text}
      </Typography>
    </Box>
  );
};
