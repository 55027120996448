import axios from 'axios';
export const API_SERVICE_URL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:8081/api'
    : 'https://app.bhabi.ca/api';

export const api = axios.create({
  baseURL: API_SERVICE_URL,
  headers: {
    // 'Content-Type': 'application/json',
  },
});
