import React from 'react';
import { createRoomRoutes } from './create-room';
import { RouteObject } from 'react-router-dom';
import { invitePlayersRoutes } from './invite-players';
import { inPlayRoutes } from './in-play';
import { profileRoutes } from './profile';
import { BhabiAppLayout } from './components';
import { chatRoutes } from './chat';
import { homeRoutes } from './home';

export const authenticatedRoutes: RouteObject[] = [
  homeRoutes(),
  createRoomRoutes(),
  profileRoutes(),
  invitePlayersRoutes(),
  chatRoutes(),
].map(route => ({
  ...route,
  ...(!route.children && {
    element: <BhabiAppLayout>{route.element}</BhabiAppLayout>,
  }),
}));

export const inRoomRoutes: RouteObject[] = [
  inPlayRoutes(),
  profileRoutes(),
  invitePlayersRoutes(),
  chatRoutes(),
].map(route => ({
  ...route,
  ...(!route.children && {
    element: <BhabiAppLayout>{route.element}</BhabiAppLayout>,
  }),
}));

export * from './verify-email';
export * from './create-room';
export * from './invite-players';
export * from './profile';
