import { Box, Paper, Typography, styled } from '@mui/material';
import React, { FC } from 'react';
import { ISO_PADDING } from '../../authenticated/consts';

export const GameRulesPage: FC = () => (
  <Box p={2} pt={9} mt={ISO_PADDING}>
    <GamesRulesPageStyled component={Paper}>
      <Typography variant="h4">Game Rules</Typography>

      <Typography variant="body1">
        Bhabi is a traditional card game that originated in Punjab, India. The
        game involves playing cards with opponents, and in each round, players
        must discard a card with the same suit and color. The game begins with
        the player who holds the Ace of Spades, and all players must follow suit
        by discarding a card from the Spades suit. The player who discards the
        highest card in the previous round initiates the subsequent rounds. The
        order of cards from highest to lowest is A, K, Q, J, ... 2. The game
        becomes more interesting and challenging when players do not possess the
        suit in play. In such cases, players can discard a card from another
        suit, which will be returned to the player who discarded the highest
        card during that round along with all the other cards played in that
        round. This way, players can exhaust their cards, and the player left
        with cards loses the game.
      </Typography>
    </GamesRulesPageStyled>
  </Box>
);

const GamesRulesPageStyled = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateRows: 'auto',
  rowGap: theme.spacing(3),
  maxWidth: 1000,
  margin: '0 auto',
  padding: theme.spacing(3, 2),
}));
