import { TextFieldProps, TextField, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import React, { useState } from 'react';
import { Controller, ControllerProps, FieldValues } from 'react-hook-form';

export type PasswordInputProps<TFieldValues extends FieldValues> = {
  controllerProps: Omit<ControllerProps<TFieldValues>, 'render'>;
  textFieldProps?: TextFieldProps;
};

export const PasswordInput = <TFieldValues extends FieldValues = FieldValues>(
  props: PasswordInputProps<TFieldValues>
): JSX.Element => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Controller
      {...props.controllerProps}
      render={({ field }) => (
        <TextField
          {...field}
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword(!showPassword)}
                edge="end"
              >
                {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
              </IconButton>
            ),
          }}
          {...props.textFieldProps}
        />
      )}
    />
  );
};
