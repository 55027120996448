import { RouteObject } from 'react-router-dom';
import React from 'react';

import { GAME_RULES_ROUTES } from './route.definitions';
import { GameRulesPage } from './page';
import { ErrorLayout, UnAuthLayout } from '../../../shared/components';

export const gameRulesRoutes: RouteObject = {
  path: GAME_RULES_ROUTES.ABSOLUTE_PATH,
  element: (
    <UnAuthLayout>
      <GameRulesPage />
    </UnAuthLayout>
  ),
  errorElement: <ErrorLayout />,
};
