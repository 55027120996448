import CircleNotificationsIcon from '@mui/icons-material/CircleNotifications';
import {
  Box,
  Badge,
  Popper,
  ClickAwayListener,
  Paper,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import React, { FC, useEffect, useState } from 'react';
import { NotificationStyled } from '../../components/styled';
import { api, useHttpMutation } from '../../../../api';
import { useSelector } from 'react-redux';
import { Store } from '../../../../redux';
import { useMutation } from 'react-query';
import { API_URLS } from '../../consts';
import { SendMessage } from 'react-use-websocket';

export interface CardsRequestNotifierProps {
  requestUsers: string[];
  sendMessage: SendMessage;
}
const menuId = 'primary-notification-menu-for-card-request';

export declare type CardsRequestInfo = {
  id: string;
  email: string;
  name: string;
  userType: string;
};

export const CardsRequestNotifier: FC<CardsRequestNotifierProps> = ({
  requestUsers,
  sendMessage,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isInviteMenuOpen = Boolean(anchorEl);
  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(!anchorEl ? event.currentTarget : null);
  };

  const { mutate: accept } = useHttpMutation<unknown, { userID: string }>(
    API_URLS.ACCEPT_CARDS_REQUEST,
    'POST'
  );

  const token = useSelector((state: Store) => state.userReducer.token);
  const [info, setInfo] = useState<CardsRequestInfo[]>([]);
  const {
    mutateAsync: getUser,
    isLoading,
    isError,
  } = useMutation({
    mutationFn: async (id: string) => {
      const res = await api({
        method: 'GET',
        url: API_URLS.GET_USER_BY_ID.replace(':id', id),
        headers: {
          Authorization: 'Bearer ' + token,
        },
      });

      return res.data;
    },
  });

  useEffect(() => {
    if (requestUsers.length) {
      const getInfo = async () => {
        const info = await Promise.all<CardsRequestInfo>(
          requestUsers.map(id => getUser(id))
        );

        setInfo(info);
      };

      getInfo();
    }
  }, [getUser, requestUsers]);

  const handleAccept = (userID: string, userType: string) => () => {
    accept(
      { userID },
      {
        onSuccess: () => {
          sendMessage('accepted-cards-request');
          setAnchorEl(null);
          if (userType === 'bot') {
            // In case of bot, we trigger message again so bot can throw card.
            setTimeout(() => {
              sendMessage('trigger bot to throw card');
            }, 800);
          }
        },
      }
    );
  };

  return (
    <>
      <Box onClick={handleMenuOpen}>
        <Badge badgeContent={requestUsers.length} color="error">
          <CircleNotificationsIcon fontSize="large" />
        </Badge>
      </Box>
      <Popper
        open={isInviteMenuOpen}
        anchorEl={anchorEl}
        placement="bottom-start"
        id={menuId}
        hidden={!requestUsers.length}
      >
        <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
          <Box
            mx={0.5}
            component={Paper}
            elevation={6}
            p={1}
            minHeight={200}
            maxWidth={500}
          >
            {isLoading && !isError ? (
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                minHeight="100%"
              >
                <CircularProgress color="primary" size={50} />
              </Box>
            ) : (
              info.map((n, i) => (
                <NotificationStyled
                  key={[n.id, i].join('-')}
                  component={Paper}
                  mt={1}
                  gridTemplateColumns="1fr auto"
                >
                  <Typography variant="body1">
                    <Typography component="span" color="primary">
                      {n.name}
                    </Typography>
                    {` is requesting for your cards. If you accept, you will
                  get rid of your cards and your cards will be given to`}{' '}
                    <Typography component="span" color="primary">
                      {n.name}.
                    </Typography>
                  </Typography>
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ height: 40 }}
                    onClick={handleAccept(n.id, n.userType)}
                  >
                    Accept
                  </Button>
                </NotificationStyled>
              ))
            )}
          </Box>
        </ClickAwayListener>
      </Popper>
    </>
  );
};
