import React, { FC, PropsWithChildren, useMemo } from 'react';
import {
  Avatar,
  AvatarProps,
  Badge,
  Typography,
  TypographyProps,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { Store } from '../../redux';
import { omit } from 'lodash';

interface UserAvatarProps {
  photoURL?: string | null;
  name?: string | null;
  userState?: 'online' | 'offline' | 'joined' | 'rejoined' | 'left';
  // hideState is used to hide the state badge
  hideState?: boolean;
  avatarProps?: AvatarProps;
  fetchCurrentUser?: boolean;
  innerWrapperProps?: TypographyProps;
}

export const UserAvatar: FC<UserAvatarProps> = ({
  photoURL,
  name,
  avatarProps,
  fetchCurrentUser,
  innerWrapperProps,
  userState,
  hideState = false,
}) => {
  const {
    name: currentUserName,
    photoURL: currentUserPhotoURL,
    state: currentUserState,
  } = useSelector((state: Store) => state.userReducer);

  const photo = useMemo(() => {
    if (fetchCurrentUser) {
      return photoURL || currentUserPhotoURL || '';
    }

    return photoURL || '';
  }, [currentUserPhotoURL, fetchCurrentUser, photoURL]);

  const state = useMemo(
    () => (fetchCurrentUser ? currentUserState : userState),
    [currentUserState, fetchCurrentUser, userState]
  );

  const avatar = (
    <Avatar
      sx={{
        bgcolor: theme =>
          theme.palette.mode === 'dark'
            ? theme.palette.primary.main
            : theme.palette.primary.light,
        width: 35,
        height: 35,
        ...avatarProps?.sx,
      }}
      {...{
        src: photo,
      }}
      {...omit(avatarProps, 'sx')}
    >
      <Typography
        fontSize={22}
        fontWeight={600}
        color="text.primary"
        {...innerWrapperProps}
      >
        {!photo?.length
          ? (!name && fetchCurrentUser
              ? currentUserName
              : name)?.[0]?.toUpperCase()
          : null}
      </Typography>
    </Avatar>
  );

  return (
    <>
      {!hideState ? (
        <UserAvatarStateBadge state={state}>{avatar}</UserAvatarStateBadge>
      ) : (
        avatar
      )}
    </>
  );
};

export const UserAvatarStateBadge: FC<
  PropsWithChildren<{ state: UserAvatarProps['userState'] }>
> = ({ children, state }) => {
  const badgeColor = useMemo(() => {
    if (state === 'online') {
      return 'success';
    }

    if (state === 'joined' || state === 'rejoined') {
      return 'success';
    }

    if (state === 'offline' || state === 'left') {
      return 'error';
    }

    return 'error';
  }, [state]);

  return (
    <Badge color={badgeColor} overlap="circular" badgeContent=" " variant="dot">
      {children}
    </Badge>
  );
};
