import { noop } from 'lodash';
import React, {
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';
import { SendMessage } from 'react-use-websocket';

type SendSocketMessageCtx = {
  sendMessage: SendMessage | null;
  setSendMessage: React.Dispatch<React.SetStateAction<SendMessage | null>>;
};

const defaultCtx: SendSocketMessageCtx = {
  sendMessage: null,
  setSendMessage: noop,
};

const SendSocketMessageCtx = createContext(defaultCtx);

export const useSendSocketMessage = () => useContext(SendSocketMessageCtx);

export const SendWSMessageProvider: FC<PropsWithChildren> = ({ children }) => {
  const [sendMessage, setSendMessage] = useState<SendMessage | null>(null);

  const value = useMemo(() => ({ sendMessage, setSendMessage }), [sendMessage]);

  return (
    <SendSocketMessageCtx.Provider value={value}>
      {children}
    </SendSocketMessageCtx.Provider>
  );
};
