import { Box, styled } from '@mui/material';
import './glowing-border.css';
import { CARD_BORDER_RADIUS } from '../../in-play/components';

export const GlowingBorderCard = styled(Box)(() => ({
  borderRadius: CARD_BORDER_RADIUS,
}));

GlowingBorderCard.defaultProps = {
  className: 'card',
};
