import { UseMutateFunction } from 'react-query';
import { useHttpMutation } from '../../api';
import { API_URLS } from '../consts';
import { useEffect, useMemo } from 'react';
import { LoggerService } from '../logger';

interface UploadResponse {
  photoURL: string;
  file: string;
  mimeType: string;
}

export const useFileUpload = () => {
  return useHttpMutation<UploadResponse, unknown, FormData>(
    API_URLS.UPLOAD_FILE,
    'POST'
  );
};

export declare type FileUploadBody = {
  file: FileList;
};

export const mutateUploadFile = (
  body: FileUploadBody,
  uploadFile: UseMutateFunction<UploadResponse, FormData, unknown, unknown>,
  callback: (res: UploadResponse | null, err?: unknown) => void
) => {
  const { file } = body;
  const formData = new FormData();
  formData.append('file', file[0]);
  uploadFile(formData, {
    onError: err => {
      LoggerService.error({ err }, 'Error uploading file');
      callback(null, err);
    },
    onSuccess: data => {
      callback(data);
    },
  });
};

export const useFileDelete = () =>
  useHttpMutation<{ msg: string }, unknown>(
    API_URLS.DELETE_PROFILE_PICTURE,
    'DELETE'
  );

export const useFileDownload = (id?: string) => {
  const { mutate, ...response } = useHttpMutation<
    {
      file: string;
      mimeType: string;
    },
    {
      id: string;
    }
  >(API_URLS.DOWNLOAD_FILE, 'POST');

  useEffect(() => {
    if (!id) return;
    mutate({ id });
  }, [id, mutate]);

  return useMemo(() => {
    if (!response.data) {
      return {
        file: null,
        res: { ...response, mutate },
      };
    }

    return {
      file: changeToBlob(response.data.file, response.data.mimeType),
      res: { ...response, mutate },
    };
  }, [response, mutate]);
};

export function changeToBlob(file: string, mimeType: string) {
  const fileData = new Uint8Array(
    atob(file)
      .split('')
      .map(char => char.charCodeAt(0))
  );
  const blob = new Blob([fileData], {
    type: mimeType,
  });

  return URL.createObjectURL(blob);
}
