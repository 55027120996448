import { RouteObject } from 'react-router-dom';
import React from 'react';
import { IN_PLAY_ROUTE } from './route.definitions';
import { InPlayPage } from './page';
export const inPlayRoutes = (
  el: JSX.Element = <InPlayPage />
): RouteObject => ({
  path: IN_PLAY_ROUTE.ABSOLUTE_PATH,
  element: el,
});
