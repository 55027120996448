import { ValidationValueMessage } from 'react-hook-form';

export const EMAIL_PATTERN: ValidationValueMessage<RegExp> = {
  value:
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  message: 'Invalid email',
};

const API_BASE_WS_URL =
  process.env.NODE_ENV === 'development'
    ? `ws://localhost:8081/api/ws`
    : `wss://bhabi-f7375e77873e.herokuapp.com/api/ws`;

export const WEBSOCKET_URLS = (BASE_URL = API_BASE_WS_URL) => ({
  BASE_URL,
  ROOM: BASE_URL + '/room',
  INVITE_NOTIFICATION: BASE_URL + '/invite-notification',
});

export const API_URLS = {
  IOS_NOTIFICATION_TOKEN: '/update-ios-token',
  RE_JOIN_ROOM: '/re-join-room',
  UPLOAD_FILE: '/s3/upload-file',
  DELETE_PROFILE_PICTURE: '/s3/delete-profile-picture',
  DOWNLOAD_FILE: '/s3/download-profile-picture',
};
