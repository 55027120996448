import {
  QueryKey,
  UseMutationOptions,
  UseQueryOptions,
  useMutation,
  useQuery,
} from 'react-query';
import { AxiosError, AxiosRequestConfig } from 'axios';
import { api } from '../base-url';
import { getStoreState } from '../../redux';

export type UseHttpQueryOptions = Omit<
  UseQueryOptions,
  'queryKey' | 'queryFn' | 'onError'
>;

export type UseHttpMutationOptions = Omit<UseMutationOptions, 'mutationFn'>;

export const useHttpMutation = <
  TData = unknown,
  TVariables = void,
  TError = AxiosError<{ message?: string }>,
  TContext = unknown,
  TOptions = UseHttpMutationOptions,
>(
  url: string,
  method: AxiosRequestConfig['method'],
  options: TOptions | Record<string, unknown> = {},
  requestConfig?: AxiosRequestConfig
) =>
  useMutation<TData, TError, TVariables, TContext>({
    ...options,
    mutationFn: async data =>
      (await httpRequest<TData, TVariables>(method, url, data, requestConfig))
        .data,
  });

export const useHttpQuery = <
  TQueryFnData = unknown,
  TData extends TQueryFnData = TQueryFnData,
  TError = AxiosError<{ message?: string }>,
  TQueryKey extends QueryKey = QueryKey,
  TVariables = undefined,
  TOptions = UseHttpQueryOptions,
>(
  QUERY_KEY: TQueryKey,
  url: string,
  method: AxiosRequestConfig['method'],
  variables: TVariables = {} as TVariables,
  options: TOptions | Record<string, unknown> = {},
  requestConfig?: AxiosRequestConfig
) =>
  useQuery<TQueryFnData, TError, TData, TQueryKey>({
    ...options,
    queryKey: QUERY_KEY,
    queryFn: async () =>
      (
        await httpRequest<TQueryFnData, TVariables>(
          method,
          url,
          variables,
          requestConfig
        )
      ).data,
  });

export const httpRequest = <T, V>(
  method: AxiosRequestConfig['method'],
  url: string,
  variables: V,
  requestConfig?: AxiosRequestConfig
): Promise<{ data: T }> =>
  api({
    method,
    url,
    data: variables,
    headers: {
      Authorization: 'Bearer ' + getStoreState().userReducer.token,
    },
    ...requestConfig,
  });
