import { useMutation } from 'react-query';
import React, { FC } from 'react';
import { ContinueWithButton } from './continue-with-google-button';
import { FirebaseError } from 'firebase/app';
import { AppleIcon } from '../../../assets';
import { grey } from '@mui/material/colors';
import {
  OAuthProvider,
  UserCredential,
  signInWithCredential,
} from 'firebase/auth';
import {
  SignInWithApple,
  SignInWithAppleOptions,
} from '@capacitor-community/apple-sign-in';

import { firebaseAuth } from '../../../shared/lib';
import { LoggerService } from '../../../shared/logger';

function getEnv(key: string) {
  const value = process.env[key];
  if (!value) {
    throw new Error(`Environment variable ${key} not found`);
  }
  return value;
}

const options: SignInWithAppleOptions = {
  clientId: getEnv('REACT_APP_CLIENT_ID_IOS'),
  redirectURI: getEnv('REACT_APP_REDIRECT_URI_IOS'),
  scopes: 'email name',
  state: 'state',
};

export const useLoginWithApple = () =>
  useMutation<UserCredential, FirebaseError>({
    mutationKey: 'login-with-apple',
    mutationFn: async () => {
      const { response: appleResult } =
        await SignInWithApple.authorize(options);

      if (!appleResult.identityToken) {
        LoggerService.error(
          'No identity token found while logging in with Apple'
        );
        throw new Error('No identity token found');
      }

      const provider = new OAuthProvider('apple.com');
      provider.addScope('email');
      provider.addScope('name');

      const p = provider.credential({
        idToken: appleResult.identityToken,
      });

      return signInWithCredential(firebaseAuth, p);
    },
  });

export const ContinueWithAppleButton: FC = () => {
  const { mutate: loginWithApple, isLoading, isError } = useLoginWithApple();

  const handleLoginWithApple = () => {
    loginWithApple(undefined, {
      onError: error => {
        LoggerService.error('Error logging in with Apple: ', error);
      },
    });
  };

  return (
    <ContinueWithButton
      {...{
        buttonProps: {
          onClick: handleLoginWithApple,
          disabled: isLoading,
          startIcon: <AppleIcon />,
          sx: {
            bgcolor: grey[800],
          },
        },
        texts: {
          title: 'Continue with Apple',
          errorMessage: 'Error logging in with Apple, please try again.',
        },
        isError,
      }}
    />
  );
};
