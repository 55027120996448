import { Outlet, RouteObject } from 'react-router-dom';
import React from 'react';
import {
  PROFILE_ROUTE,
  PUBLIC_PROFILE_ROUTE,
  UPLOAD_PROFILE_PHOTO_ROUTE,
} from './route.definitions';
import { ProfilePage } from './page';
import { PublicProfilePage } from './public-profile';
import { BhabiAppLayout } from '../components';
import { UploadProfilePicture } from './upload-profile-picture';

export const profileRoutes = (
  el: JSX.Element = <ProfilePage />
): RouteObject => ({
  path: PROFILE_ROUTE.ABSOLUTE_PATH,
  element: (
    <BhabiAppLayout>
      <Outlet />
    </BhabiAppLayout>
  ),
  children: [
    {
      index: true,
      element: el,
    },
    {
      path: PUBLIC_PROFILE_ROUTE.KEY,
      element: <PublicProfilePage />,
    },
    {
      path: UPLOAD_PROFILE_PHOTO_ROUTE.KEY,
      element: <UploadProfilePicture />,
    },
  ],
});
