import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { FC } from 'react';

export const SpadesIcon: FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 57.938 57.938" {...props}>
    {spadesIcon}
  </SvgIcon>
);

const spadesIcon = (
  <>
    <g>
      <path
        style={{ fill: '#2C2F38' }}
        d="M54.822,24.938C51.086,18.334,43.441,8.828,29.154,0.051c-0.112-0.069-0.258-0.069-0.369,0
		C14.5,8.828,6.853,18.045,3.116,24.649c-4.395,7.767-1.889,17.151,6.104,21.119c2.084,1.034,3.599,1.17,5.396,1.17
		c0,0,0.44-0.015,1.164-0.123c3.212-0.48,6.165-2.041,8.45-4.349l0.325-0.328c0.475-0.48,1.29-0.103,1.227,0.57
		c-0.339,3.645-0.998,9.113-3.787,12.591c-0.855,1.066-0.155,2.64,1.212,2.64h11.534c1.363,0,2.058-1.57,1.204-2.633
		c-2.824-3.516-3.462-9.069-3.807-12.715c-0.054-0.569,0.648-0.876,1.033-0.453l0,0c1.992,2.194,4.562,3.806,7.428,4.56
		c1.595,0.42,2.721,0.53,2.721,0.53c1.797,0,3.313-0.136,5.396-1.17C56.711,42.089,59.217,32.705,54.822,24.938z"
      />
    </g>
  </>
);
