import {
  Box,
  Button,
  Stack,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import ReplayIcon from '@mui/icons-material/Replay';
import RemoveIcon from '@mui/icons-material/Remove';
import React, { FC, createRef, useEffect, useRef } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { Texts } from '../../../shared/types';
import { VERIFY_EMAIL_ROUTE } from './route.definitions';
import { useHttpMutation } from '../../../api';
import { useAlert } from '../../../shared/components';
import { useNavigate } from 'react-router-dom';
import { API_URLS } from '../consts';
import { BhabiLogo } from '../../../assets';
import { BhabiStorage } from '../../../shared/lib';
import { useDispatch } from 'react-redux';
import { getUser } from '../../../redux/reducers';
import { AnyAction } from '@reduxjs/toolkit';
import { HOME_ROUTE } from '../home';

export const CodeInput = styled(TextField)(() => ({
  width: 55,
  '&.MuiTextField-root': {
    fontSize: 22,
  },
  '& .MuiInputBase-input': {
    textAlign: 'center',
  },
}));

export const VerifyEmailPageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(10),
  height: '100vh',
}));

export declare type VerificationCode = {
  a: string;
  b: string;
  c: string;
  d: string;
  e: string;
};

export declare type KeysOfVerificationCode = 'a' | 'b' | 'c' | 'd' | 'e';

const defaultValues: VerificationCode = {
  a: '',
  b: '',
  c: '',
  d: '',
  e: '',
};

const keysArray = Object.keys(defaultValues) as KeysOfVerificationCode[];

export interface VerifyEmailPageProps {
  texts?: Texts<'title' | 'submit' | 'resendEmail'>;
}

const useVerifyEmail = () =>
  useHttpMutation<{ message: string }, { code: number }>(
    API_URLS.VERIFY_EMAIL,
    'POST'
  );

export const VerifyEmailPage: FC<VerifyEmailPageProps> = ({
  texts = {
    title: VERIFY_EMAIL_ROUTE.TITLE,
    submit: 'Verify Email',
    resendEmail: 'Haven`t received email?',
    resendButton: 'resend email',
  },
}) => {
  const {
    handleSubmit,
    formState: { errors },
    getValues,
    control,
  } = useForm<VerificationCode>({
    mode: 'onChange',
    defaultValues,
  });

  const { mutate: verifyEmail } = useVerifyEmail();
  const { addAlert } = useAlert();

  const nav = useNavigate();

  const inputRefs = useRef(keysArray.map(() => createRef<HTMLInputElement>()));
  const dispatch = useDispatch();

  useEffect(() => {
    inputRefs.current[0].current?.focus();
  }, [inputRefs]);

  const onSubmit: SubmitHandler<VerificationCode> = data => {
    const c = Object.values(data).join('');
    const code = Number(c);

    verifyEmail(
      { code },
      {
        onError: () => {
          addAlert({
            message: 'Error verifying email',
            type: 'error',
          });
        },
        onSuccess: () => {
          addAlert({
            message: 'Email has been verified',
            type: 'success',
          });

          BhabiStorage.getItem(token => {
            if (!token) {
              return;
            }

            dispatch(getUser(token) as unknown as AnyAction);

            // navigate to the app
            nav(HOME_ROUTE.ABSOLUTE_PATH);
          });
        },
      }
    );
  };

  return (
    <VerifyEmailPageWrapper pt={5}>
      <BhabiLogo style={{ height: 150, width: 200 }} />
      <Typography variant="h4" fontWeight={600}>
        {texts.title}
      </Typography>
      <Stack
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        display="flex"
        flexDirection="column"
        gap={5}
        alignItems="center"
      >
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          gap={0.5}
          px={1}
        >
          {keysArray.map((formKey, i) => (
            <React.Fragment key={[formKey, i].join('-')}>
              <Controller
                control={control}
                name={formKey}
                rules={{
                  required: true,
                  validate: value => value.length === 1,
                }}
                render={({ field: { onChange, ...field } }) => (
                  <CodeInput
                    {...field}
                    inputRef={inputRefs.current[i]}
                    onChange={e => {
                      if (
                        getValues(formKey).length === 1 &&
                        e.target.value.length > 1
                      ) {
                        return;
                      }

                      if (e.target.value && i < inputRefs.current.length - 1) {
                        inputRefs.current[i + 1].current?.focus();
                      }

                      onChange(e);
                    }}
                    error={!!errors[formKey]}
                  />
                )}
              />

              {i !== keysArray.length - 1 && <RemoveIcon />}
            </React.Fragment>
          ))}
        </Box>
        <Button type="submit" variant="contained">
          {texts.submit}
        </Button>
      </Stack>
      <Box display="flex" gap={1} alignItems="center">
        {texts.resendEmail}
        <ResendEmail />
      </Box>
    </VerifyEmailPageWrapper>
  );
};

export interface ResendEmailProps {
  texts?: Texts<'resendButton'>;
}
export const ResendEmail: FC<ResendEmailProps> = ({
  texts = {
    resendButton: 'Resend Email',
  },
}) => {
  const { mutate } = useHttpMutation(API_URLS.RESEND_VERIFICATION_EMAIL, 'get');

  const { addAlert } = useAlert();

  return (
    <Typography
      {...{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
        color: 'primary',
        sx: {
          cursor: 'pointer',
        },
        onClick: () =>
          mutate(undefined, {
            onError: () => {
              addAlert({
                type: 'error',
                message: 'Error resending email',
              });
            },
            onSuccess: () => {
              addAlert({
                type: 'success',
                message: 'Email sent successfully',
              });
            },
          }),
      }}
    >
      {texts.resendButton}
      <ReplayIcon color="inherit" />
    </Typography>
  );
};
