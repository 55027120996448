import { RouteObject } from 'react-router-dom';
import React from 'react';
import { Toc } from './page';
import { TOC_ROUTES } from './route.definitions';
import { UnAuthLayout } from '../../../shared/components';

export const tocRoutes: RouteObject = {
  path: TOC_ROUTES.ABSOLUTE_PATH,
  element: (
    <UnAuthLayout>
      <Toc />
    </UnAuthLayout>
  ),
};
