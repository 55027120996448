import React, { FC, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MainFrame } from '../components/main-frame';
import { useHttpMutation } from '../../../api';
import { PasswordInput, useAlert } from '../../../shared/components';
import { Button, TextField, Typography } from '@mui/material';
import { EMAIL_PATTERN } from '../../../shared/consts';
import { Texts } from '../../../shared/types';
import { RESET_PASSWORD_ROUTES } from './route.definitions';
import { useNavigate, useSearchParams } from 'react-router-dom';

export declare type RequestResetPasswordInput = {
  email: string;
};

export const useRequestPasswordReset = () =>
  useHttpMutation<{ token: string }, RequestResetPasswordInput>(
    '/reset-password-link',
    'POST'
  );

export interface RequestResetPasswordProps {
  texts?: Texts<'resetPasswordTitle' | 'button'>;
}

export const RequestResetPassword: FC<RequestResetPasswordProps> = ({
  texts = {
    resetPasswordTitle: RESET_PASSWORD_ROUTES.TITLE,
    button: 'Send reset password link',
  },
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<RequestResetPasswordInput>({
    defaultValues: {
      email: '',
    },
    mode: 'onSubmit',
  });

  const { mutate: requestResetPassword, isLoading } = useRequestPasswordReset();
  const { addAlert } = useAlert();

  const onSubmit: SubmitHandler<RequestResetPasswordInput> = data => {
    requestResetPassword(data, {
      onSuccess: () => {
        addAlert({
          message: 'Reset password link sent',
          type: 'success',
        });
        reset();
      },
      onError: err => {
        addAlert({
          message:
            err?.response?.data?.message ||
            'Failed to send reset password link',
          type: 'error',
        });
      },
    });
  };

  return (
    <MainFrame
      stackProps={{ onSubmit: handleSubmit(onSubmit) }}
      texts={{
        linkMessage: 'Go back to Sign In Page',
        forgotPassword: '',
      }}
      linkPath="/"
    >
      <Typography variant="h4" pb={4}>
        {texts.resetPasswordTitle}
      </Typography>
      <TextField
        {...register('email', {
          required: `Email is required.`,
          pattern: EMAIL_PATTERN,
        })}
        placeholder="Email"
        error={!!errors.email}
        helperText={<>{errors.email?.message}</>}
      />

      <Button type="submit" variant="contained" disabled={isLoading}>
        {texts.button}
      </Button>
    </MainFrame>
  );
};

export declare type ResetPasswordInput = {
  password: string;
  confirmPassword: string;
  token: string;
};

export const useResetPassword = () =>
  useHttpMutation<
    { message: string },
    Omit<ResetPasswordInput, 'confirmPassword'>
  >('/reset-password', 'POST');

export const ResetPasswordPage: FC<RequestResetPasswordProps> = ({
  texts = {
    resetPasswordTitle: RESET_PASSWORD_ROUTES.TITLE,
    button: 'Reset Password',
  },
}) => {
  const [searchParams] = useSearchParams();

  const token = useMemo(() => searchParams.get('token'), [searchParams]);

  const {
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    control,
  } = useForm<Omit<ResetPasswordInput, 'token'>>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    mode: 'onSubmit',
  });

  const { mutate: resetPassword, isLoading } = useResetPassword();

  const { addAlert } = useAlert();

  const navigate = useNavigate();

  const onSubmit: SubmitHandler<Omit<ResetPasswordInput, 'token'>> = data => {
    if (!token) {
      addAlert({
        message: 'Invalid reset password link',
        type: 'error',
      });

      navigate('/');
      return;
    }

    resetPassword(
      { ...data, token },
      {
        onSuccess: () => {
          addAlert({
            message: 'Password reset successful',
            type: 'success',
          });
          reset();
          navigate('/');
        },
        onError: err => {
          addAlert({
            message: err?.response?.data?.message || 'Error resetting password',
            type: 'error',
          });
        },
      }
    );
  };

  return (
    <>
      {!token ? (
        <RequestResetPassword />
      ) : (
        <MainFrame
          stackProps={{ onSubmit: handleSubmit(onSubmit) }}
          texts={{
            linkMessage: 'Back to Sign In',
            forgotPassword: '',
          }}
          linkPath="/"
        >
          <Typography variant="h3" pb={4}>
            {texts.resetPasswordTitle}
          </Typography>
          <PasswordInput<Omit<ResetPasswordInput, 'token'>>
            controllerProps={{
              control,
              name: 'password',
              rules: {
                required: `Password is required.`,
                validate: value =>
                  value.length >= 8 || `Password is too short.`,
              },
            }}
            textFieldProps={{
              label: 'Password',
              placeholder: 'Password',
              error: !!errors.password,
              helperText: <>{errors.password?.message}</>,
            }}
          />

          <PasswordInput<Omit<ResetPasswordInput, 'token'>>
            controllerProps={{
              control,
              name: 'confirmPassword',
              rules: {
                required: `Confirm Password is required.`,
                validate: value =>
                  value === getValues('password') || `Passwords do not match.`,
              },
            }}
            textFieldProps={{
              label: 'Confirm Password',
              placeholder: 'Confirm Password',
              error: !!errors.confirmPassword,
              helperText: <>{errors.confirmPassword?.message}</>,
            }}
          />

          <Button type="submit" variant="contained" disabled={isLoading}>
            {texts.button}
          </Button>
        </MainFrame>
      )}
    </>
  );
};
