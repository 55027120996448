import { useTheme } from '@mui/material';
import React, { FC, ImgHTMLAttributes } from 'react';
import bhabiLogoWhite from './Bhabi-logo-black.png';
import bhabiLogoBlack from './Bhabi-logo-white.png';

export const BhabiLogo: FC<ImgHTMLAttributes<HTMLImageElement>> = ({
  ...props
}) => {
  const {
    palette: { mode },
  } = useTheme();

  return (
    <img
      src={mode === 'dark' ? bhabiLogoBlack : bhabiLogoWhite}
      style={{
        mixBlendMode: 'color-burn',
        height: 200,
        width: 200,
      }}
      {...props}
    />
  );
};
