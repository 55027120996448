import LogoutIcon from '@mui/icons-material/Logout';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import React, { useState } from 'react';

import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  styled,
} from '@mui/material';
import { useLogout } from '../hooks';
import { useNavigate } from 'react-router-dom';
import { PROFILE_ROUTE } from '../profile';
import { UserAvatar } from '../../../shared/components';

const AppBarListItem = styled(ListItem)(({ theme }) => ({
  cursor: 'pointer',
  padding: theme.spacing(1),
}));

export interface ProfileButtonProps {
  hideLogout?: boolean;
}

export const ProfileButton: React.FC<ProfileButtonProps> = ({
  hideLogout = false,
}) => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const isMenuOpen = Boolean(anchorEl);

  const { logout } = useLogout();

  const nav = useNavigate();

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const menuId = 'primary-account-menu';
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      keepMounted
      open={isMenuOpen}
      onClose={handleMenuClose}
      sx={{
        '& .MuiMenu-paper': {
          minWidth: '20ch',
          width: 'fit-content',
        },
      }}
    >
      <List>
        <AppBarListItem
          onClick={() => {
            nav(PROFILE_ROUTE.ABSOLUTE_PATH);
            handleMenuClose();
          }}
          disablePadding
        >
          <ListItemIcon>
            <PersonOutlineIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary="Profile" />
        </AppBarListItem>
        {!hideLogout && (
          <AppBarListItem
            disablePadding
            onClick={() => {
              logout();
              handleMenuClose();
            }}
          >
            <ListItemIcon>
              <LogoutIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText primary="Logout" />
          </AppBarListItem>
        )}
      </List>
    </Menu>
  );

  return (
    <>
      <IconButton
        aria-label="account of current user"
        onClick={handleProfileMenuOpen}
        edge="end"
      >
        <UserAvatar
          hideState
          fetchCurrentUser
          avatarProps={{
            sx: {
              width: 25,
              height: 25,
            },
          }}
        />
      </IconButton>
      {renderMenu}
    </>
  );
};
