import { Box, BoxProps, IconButton, styled } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import React, { FC, PropsWithChildren } from 'react';
import { useNavigate } from 'react-router-dom';
import { Footer } from './footer';

// const ISO_PADDING = 'env(safe-area-inset-top)';

const ToolbarStyled = styled(Box)(() => ({
  display: 'grid',
  gap: 1,
  gridTemplateColumns: '1fr auto auto auto auto auto',
}));

export const TopBarLayout: FC<
  PropsWithChildren<{ outerBoxProps?: BoxProps }>
> = ({ children, outerBoxProps }) => (
  <Box
    {...outerBoxProps}
    sx={{ flexGrow: 1, position: 'relative', ...outerBoxProps?.sx }}
  >
    <Box
      className="fixed top-0 left-0 w-full px-6 z-[3000]"
      sx={{
        backgroundColor: 'transparent',
      }}
    >
      <ToolbarStyled>{children}</ToolbarStyled>
    </Box>
  </Box>
);

export const UnAuthLayout: FC<PropsWithChildren> = ({ children }) => {
  const nav = useNavigate();
  return (
    <Box display="grid" gridTemplateRows="auto" rowGap={1} overflow="hidden">
      <TopBarLayout>
        <Box>
          <IconButton
            onClick={() => {
              nav('/');
            }}
          >
            <ArrowBackOutlinedIcon />
          </IconButton>
        </Box>
      </TopBarLayout>
      {children}
      <Footer />
    </Box>
  );
};
