import React, {
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  createContext,
  useContext,
  useMemo,
  useState,
} from 'react';
import { Card } from '../types';
import { noop } from 'lodash';

export type GameState = 'clear' | 'playing';

export type ThrowCardCtx = {
  thrownCard: Card | null;
  setThrownCard: Dispatch<SetStateAction<Card | null>>;
  gameState: GameState;
  setGameState: Dispatch<React.SetStateAction<GameState>>;
  setGridWidth: Dispatch<SetStateAction<number>>;
  gridWidth: number;
};

const defaultThrowCardCtx: ThrowCardCtx = {
  thrownCard: null,
  setThrownCard: noop,
  gameState: 'playing',
  setGameState: noop,
  setGridWidth: noop,
  gridWidth: 0,
};

const ThrowCard = createContext(defaultThrowCardCtx);

export const useThrowCard = () => useContext(ThrowCard);

export const ThrowCardProvider: FC<PropsWithChildren> = ({ children }) => {
  const [thrownCard, setThrownCard] = useState<Card | null>(null);
  const [gameState, setGameState] = useState<GameState>('playing');
  const [gridWidth, setGridWidth] = useState<number>(0);

  const value = useMemo(
    () => ({
      thrownCard,
      setThrownCard,
      gameState,
      setGameState,
      setGridWidth,
      gridWidth,
    }),
    [thrownCard, gameState, gridWidth]
  );

  return <ThrowCard.Provider value={value}>{children}</ThrowCard.Provider>;
};
