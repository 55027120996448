function secretString(key: string): string {
  const value = process.env?.[key];
  if (!value) {
    throw new Error(`Missing environment variable: ${key}`);
  }
  return value;
}

export const ENV: BhabiEnv = {
  IS_DEV: secretString('NODE_ENV') === 'development',
  LOG_LEVEL:
    (secretString('REACT_APP_LOG_LEVEL') as BhabiEnv['LOG_LEVEL']) || 'debug',
  REACT_APP_HIDE_THEME_CHANGE:
    secretString('REACT_APP_HIDE_THEME_CHANGE') === 'true',

  FIREBASE_CONFIG: {
    apiKey: secretString('REACT_APP_API_KEY'),
    authDomain: secretString('REACT_APP_AUTH_DOMAIN'),
    projectId: secretString('REACT_APP_PROJECT_ID'),
    storageBucket: secretString('REACT_APP_STORAGE_BUCKET'),
    messagingSenderId: secretString('REACT_APP_MESSAGING_SENDER_ID'),
    appId: secretString('REACT_APP_APP_ID'),
    measurementId: secretString('REACT_APP_MEASUREMENT_ID'),
  },
};
