export const SIGN_IN_ROUTES = {
  TITLE: 'Sign In',
  ABSOLUTE_PATH: '/',
  EXACT_PATH: '/',
};

export const RESET_PASSWORD_ROUTES = {
  TITLE: 'Reset Password',
  ABSOLUTE_PATH: '/reset-password',
  EXACT_PATH: '/reset-password',
};
