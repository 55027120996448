import { Box, Button, Typography, styled } from '@mui/material';
import React, { FC } from 'react';
import { BhabiLogo } from '../../assets';
import { Link, RouteObject } from 'react-router-dom';

const NotFoundPageWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  height: '100vh',
  width: '100vw',
}));

export const PageNotFound: FC = () => (
  <NotFoundPageWrapper pt={5}>
    <BhabiLogo style={{ height: 200, width: 200 }} />
    <Typography variant="h5" fontWeight={500}>
      Oops! Something went wrong.
    </Typography>
    <Button variant="contained" component={Link} to="/">
      Back Home
    </Button>
  </NotFoundPageWrapper>
);

export const notFoundRoute: RouteObject = {
  path: '*',
  element: <PageNotFound />,
};
