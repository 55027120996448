import React, { FC, PropsWithChildren } from 'react';
import { Texts } from '../types';
import { Box, BoxProps, Typography } from '@mui/material';
import { BhabiLogo } from '../../assets';

export interface ErrorProps {
  level?: 'info' | 'warning' | 'error';
  texts?: Texts<'defaultError'>;
  outerBoxProps?: BoxProps;
}

export const ErrorLayout: FC<PropsWithChildren<ErrorProps>> = ({
  level = 'error',
  texts = {
    defaultError: 'Something went wrong',
  },
  outerBoxProps,
  children,
}) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height="100vh"
    gap={1}
    {...outerBoxProps}
  >
    <BhabiLogo style={{ height: 120, width: 120 }} />
    <Typography
      className="px-2 text-center"
      sx={{ color: theme => theme.palette?.[level].main }}
      fontWeight={500}
    >
      {texts.defaultError}
    </Typography>
    {children}
  </Box>
);
