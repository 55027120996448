import React, { FC } from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import { NotConcludedRoomsResponse } from '../types';
import { useHttpMutation, useHttpQuery } from '../../../api';
import { API_URLS } from '../consts';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  BhabiLink,
  ErrorLayout,
  LoadingLayout,
  UserAvatar,
  useAlert,
} from '../../../shared/components';
import { BhabiStorage } from '@/shared/lib';
import { getUser } from '../../../redux/reducers';
import { AnyAction } from '@reduxjs/toolkit';
import { IN_PLAY_ROUTE } from '../in-play';
import { LoggerService } from '../../../shared/logger';
import { Box, Paper, Typography, styled } from '@mui/material';
import {
  ActionButtonStyled,
  NotificationStyled,
  RoomInfoGridStyled,
  roomInfoGridClassNames,
} from './styled';
import { useRandomColor } from '../in-play/components/playground';
import { PUBLIC_PROFILE_ROUTE } from '../profile';
import { InvitePlayerPageLayout } from '../invite-players/components';

export const UserRooms: FC = () => {
  const { data, isLoading, isError } = useHttpQuery<
    NotConcludedRoomsResponse[]
  >('notConcludedRooms', API_URLS.NOT_CONCLUDED_ROOMS, 'GET');

  const { mutate: reJoinRoom } = useHttpMutation<
    { token: string },
    { roomID: string }
  >(API_URLS.RE_JOIN_ROOM, 'POST');

  const dispatch = useDispatch();
  const nav = useNavigate();
  const { addAlert } = useAlert();

  const onHandleReJoinRoom = (roomID: string) => () => {
    reJoinRoom(
      { roomID },
      {
        onSuccess: res => {
          BhabiStorage.setToken(res.token);
          dispatch(getUser(res.token) as unknown as AnyAction);
          nav(IN_PLAY_ROUTE.ABSOLUTE_PATH);
        },
        onError: () => {
          LoggerService.error('Re-join room failed');
          addAlert({
            type: 'error',
            message: 'Re-join room failed',
          });
        },
      }
    );
  };

  if (isLoading && !isError) {
    return <LoadingLayout height="auto" />;
  }

  if (isError) {
    return <ErrorLayout />;
  }

  if (!data?.length) {
    return (
      <Typography
        textAlign="center"
        color="textSecondary"
      >{`No pending rooms`}</Typography>
    );
  }

  return (
    <InvitePlayerPageLayout>
      {data?.map((n, i) => (
        <NotificationStyled
          key={[n.roomID, i].join('-')}
          component={Paper}
          mt={1}
        >
          <Box
            display="grid"
            gridTemplateColumns="auto auto auto"
            gap={1}
            p={1}
          >
            <Typography variant="body1">{`You have a pending room`}</Typography>
            <ActionButtonStyled
              variant="outlined"
              color="success"
              size="small"
              disabled={false}
              onClick={onHandleReJoinRoom(n.roomID)}
            >
              Join
            </ActionButtonStyled>
          </Box>
          <UsersInRoomList list={n.users} />
          <RoomInfoGridStyled>
            <Box px={1} className={roomInfoGridClassNames.infoBox.name}>
              <Typography
                variant="body1"
                className={roomInfoGridClassNames.infoBox.infoText}
              >
                {`Players: ${n.numberOfPlayers}`}
              </Typography>
            </Box>

            <Box display="flex" alignItems="center" gap={1}>
              {n.isFull ? (
                <CloseIcon color="error" fontSize="small" />
              ) : (
                <CheckCircleIcon color="success" fontSize="small" />
              )}
              <Typography>{n.isFull ? 'Full' : 'Open'}</Typography>
              {/* <Typography
                variant="body2"
                fontSize="small"
                color="secondary"
                fontWeight={500}
                ml={3}
              >{`ID: ${n.roomID?.split('-')?.[0]}`}</Typography> */}
            </Box>
          </RoomInfoGridStyled>
        </NotificationStyled>
      ))}
    </InvitePlayerPageLayout>
  );
};

export interface UsersInRoomListProps {
  list: {
    email: string;
    userID: string;
    name: string;
    photoURL: string;
    state: 'online' | 'offline' | 'joined' | 'left' | 'rejoined';
  }[];
}

export const UsersInRoomList: FC<UsersInRoomListProps> = ({ list }) => {
  const avatarColors = useRandomColor(list.length);

  return (
    <PlayersInfoWrapper noOfPlayers={list.length}>
      {list.map((user, i) => (
        <Box key={i} display="flex" flexDirection="column" alignItems="center">
          <BhabiLink
            to={[
              PUBLIC_PROFILE_ROUTE.EXACT_PATH,
              '?user_id=',
              user.userID,
            ].join('')}
            style={{ textDecoration: 'none' }}
          >
            <UserAvatar
              {...{
                photoURL: user.photoURL,
                name: user.name,
                userState: user.state,
                avatarProps: {
                  sx: {
                    bgcolor: avatarColors[i],
                    width: 25,
                    height: 25,
                  },
                },
              }}
            />
          </BhabiLink>
        </Box>
      ))}
    </PlayersInfoWrapper>
  );
};
export interface PlayersWrapperProps {
  noOfPlayers: number;
}

export const PlayersInfoWrapper = styled(Box, {
  shouldForwardProp: props => props !== 'noOfPlayers',
})<PlayersWrapperProps>(({ theme, noOfPlayers }) => ({
  display: 'grid',
  gridTemplateColumns: `repeat(${Math.min(noOfPlayers, 5)}, auto)`,
  gridTemplateRows: 'auto',
  width: 'fit-content',
  gap: theme.spacing(1),
  padding: theme.spacing(1),
}));
