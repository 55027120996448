import { RouteObject } from 'react-router-dom';
import React from 'react';
import { INVITE_PLAYER_ROUTE } from './route.definitions';
import { InvitePlayersPage } from './page';

export const invitePlayersRoutes = (
  el: JSX.Element = <InvitePlayersPage />
): RouteObject => ({
  path: INVITE_PLAYER_ROUTE.ABSOLUTE_PATH,
  element: el,
});
