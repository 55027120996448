import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useTabChange = (tabs: string[]) => {
  const [value, setValue] = React.useState(0);
  const [params, setSearchParams] = useSearchParams();

  useEffect(() => {
    const tab = params.get('tab');
    if (!tab) {
      setSearchParams({ tab: tabs[value] });
      return;
    }
    if (tab !== tabs[value]) {
      setValue(tabs.indexOf(tab));
    }
  }, [params, tabs, setSearchParams, value]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setSearchParams({ tab: tabs[newValue] });
  };

  return { value, handleTabChange };
};

interface TabPanelProps {
  index: number;
  value: number;
}

export const CustomTabPanel: FC<PropsWithChildren<TabPanelProps>> = props => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
};

export function tabProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
