import React, { FC } from 'react';
import { Provider as ReduxProvider } from 'react-redux';
import { ThemeProvider } from './theme';
import { store } from './redux';
import { PMQueryProvider } from './api';
import { GameRoutes } from './router';

import { AlertProvider, PopUpBoxProvider } from './shared/components';

import { ScreenOrientation } from '@capacitor/screen-orientation';
import { LoggerService } from './shared/logger';

ScreenOrientation.lock({ orientation: 'landscape' }).catch(error => {
  LoggerService.error('ScreenOrientation', error);
});

export const App: FC = () => {
  return (
    <ReduxProvider store={store}>
      <PMQueryProvider>
        <ThemeProvider>
          <AlertProvider />
          <PopUpBoxProvider>
            <GameRoutes />
          </PopUpBoxProvider>
        </ThemeProvider>
      </PMQueryProvider>
    </ReduxProvider>
  );
};
