import { SvgIcon, SvgIconProps } from '@mui/material';
import React, { FC } from 'react';

export const DiamondsIcon: FC<SvgIconProps> = props => (
  <SvgIcon viewBox="0 0 512 512" {...props}>
    {diamondsIcon}
  </SvgIcon>
);

const diamondsIcon = (
  <>
    <g>
      <polygon
        style={{ fill: '#D71E00' }}
        points="256,0 45.714,256 256,512 466.286,256 	"
      />
      <path
        style={{ fill: '#B21606' }}
        d="M248.872,448.656c-3.162,3.937-2.533,9.692,1.404,12.853c3.937,3.162,9.692,2.533,12.853-1.404
		l64-79.698c3.162-3.937,2.533-9.692-1.404-12.853c-3.937-3.162-9.692-2.533-12.853,1.404L248.872,448.656z"
      />
    </g>
  </>
);
