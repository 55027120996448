import { NativeAudio, PreloadOptions } from '@capacitor-community/native-audio';
import { Capacitor } from '@capacitor/core';
import { LoggerService } from '../logger';

export class SoundEffect {
  public thoka = 'thoka';
  public punch = 'punch';
  public clear = 'clear';
  public notification = 'notification';

  constructor() {
    if (Capacitor.getPlatform() === 'web') {
      return;
    }
    this.preLoadSounds();
  }

  async preLoadSounds() {
    try {
      await Promise.all(
        this.bhabiSounds().map(async sound => await NativeAudio.preload(sound))
      );
      LoggerService.info('Sounds preloaded');
    } catch (error) {
      LoggerService.error('Error while preloading sounds', error);
    }
  }

  async playSound(sound: string) {
    if (Capacitor.getPlatform() === 'web') return;
    try {
      await NativeAudio.play({ assetId: sound });
      LoggerService.info('Sound played');
    } catch (error) {
      LoggerService.error('Error while playing sound', error);
    }
  }

  bhabiSounds() {
    const soundConfig: PreloadOptions[] = [
      {
        assetId: this.thoka,
        assetPath: this.createAssetPath('thoka.mp3'),
        isUrl: false,
        audioChannelNum: 1,
      },
      {
        assetId: this.punch,
        assetPath: this.createAssetPath('punch.wav'),
        isUrl: false,
        audioChannelNum: 0.6,
      },
      // {
      //   assetId: this.clear,
      //   assetPath: this.createAssetPath('shuffle-card.wav'),
      //   isUrl: false,
      //   audioChannelNum: 1,
      // },
      {
        assetId: this.notification,
        assetPath: this.createAssetPath('notification.mp3'),
        isUrl: false,
        audioChannelNum: 1,
      },
    ];

    return soundConfig;
  }

  createAssetPath(path: string) {
    const platform = Capacitor.getPlatform();
    if (platform === 'ios') {
      return ['sounds', path].join('/');
    }

    return ['audio', path].join('/');
  }
}

export const soundEffect = new SoundEffect();
