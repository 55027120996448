import React, {
  CSSProperties,
  FC,
  RefObject,
  useCallback,
  useState,
} from 'react';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore';
import { db, firebaseAuth } from '../../../../shared/lib';
import { LoggerService } from '../../../../shared/logger';
import { IconButton, TextField, styled } from '@mui/material';
import { useAuthState } from 'react-firebase-hooks/auth';
import { useLocation } from 'react-router-dom';
import { IN_PLAY_ROUTE } from '../../in-play';
export interface SendMessageProps {
  scroll: RefObject<HTMLSpanElement>;
  receiverID?: string;
  roomID?: string;
  messageFormProps?: CSSProperties;
}

export const SendMessage: FC<SendMessageProps> = ({
  scroll,
  receiverID = '',
  roomID = '',
  messageFormProps = {},
}) => {
  const [message, setMessage] = useState('');
  const [user] = useAuthState(firebaseAuth);
  const { pathname } = useLocation();

  const sendMessage = useCallback(
    async (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (message.trim() === '' || !user) {
        LoggerService.info('Message is empty');
        return;
      }
      const { uid, displayName, photoURL } = user;
      await addDoc(collection(db, 'chat'), {
        message,
        groupID: pathname === IN_PLAY_ROUTE.ABSOLUTE_PATH ? roomID : '',
        name: displayName,
        avatar: photoURL,
        createdAt: serverTimestamp(),
        senderID: uid,
        receiverID,
        isRead: 'false',
        threadID:
          pathname === IN_PLAY_ROUTE.ABSOLUTE_PATH ? [] : [uid, receiverID],
      });
      setMessage('');
      scroll.current?.scrollIntoView({ behavior: 'smooth' });
    },
    [message, receiverID, roomID, scroll, user, pathname]
  );

  return (
    <SendMessageForm onSubmit={sendMessage} style={messageFormProps}>
      <TextField
        id="messageInput"
        name="messageInput"
        type="text"
        placeholder="message"
        size="small"
        autoComplete="on"
        value={message}
        onChange={e => setMessage(e.target.value)}
        multiline
        InputProps={{
          style: { height: `fit-content`, fontSize: 14 },
        }}
        sx={{
          width: '100%',
        }}
      />
      <IconButton type="submit" size="small" sx={{ marginLeft: -5 }}>
        <SendOutlinedIcon />
      </IconButton>
    </SendMessageForm>
  );
};

export const SendMessageForm = styled('form')(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(1, 2),
  display: 'flex',
  alignItems: 'center',
}));
