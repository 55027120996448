import { RouteObject } from 'react-router-dom';
import React from 'react';
import { VERIFY_EMAIL_ROUTE } from './route.definitions';
import { VerifyEmailPage } from './page';

export const verifyEmailRoutes = (
  el: JSX.Element = <VerifyEmailPage />
): RouteObject => ({
  path: VERIFY_EMAIL_ROUTE.ABSOLUTE_PATH,
  element: el,
});
