import React, { FC } from 'react';
import { useToggleTheme } from '../../../theme/hooks';
import { MaterialUISwitch } from './styled';
import { useTheme } from '@mui/material';

export const ThemeSwitch: FC = () => {
  const toggle = useToggleTheme();

  const {
    palette: { mode },
  } = useTheme();

  return (
    <MaterialUISwitch
      sx={{ m: 1 }}
      defaultChecked={mode === 'light'}
      onChange={(_, checked) => {
        toggle(checked ? 'light' : 'dark');
      }}
    />
  );
};
