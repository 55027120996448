import { PaletteMode } from '@mui/material';
import randomColor from 'randomcolor';

export class GenerateAvatarColor {
  public avatarColors: string[] = [];
  constructor(
    private mode: PaletteMode,
    private noOfPlayer: number
  ) {
    this.avatarColors = randomColor({
      luminosity: mode,
      count: noOfPlayer,
      hue: 'random',
    });
  }
}
