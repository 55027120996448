import { Card } from '../types';

export function offsetFromCenter<T>(array: T[], index: number): number {
  return index - Math.floor(array.length / 2);
}

export function angle(userDeck: Card[], space = 0.05) {
  return (i: number) => {
    const factor = userDeck.length / 4;
    let x = offsetFromCenter(userDeck, i) * space;
    if (userDeck.length % 2 === 0) x += 0.025;
    return x * (Math.PI / factor);
  };
}
