import React, {
  Dispatch,
  FC,
  forwardRef,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';

import { PlayerInfo } from '../../../types';
import { useRandomColor } from './hooks';
import { useLeaveRoom } from '../../../components';
import {
  BhabiLink,
  ErrorLayout,
  UserAvatarStateBadge,
} from '../../../../../shared/components';
import {
  Avatar,
  Box,
  Button,
  Chip,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';
import { findAvatarBgColor } from './utils';
import { PlayersAvatarWrapper } from './styled';
import { PUBLIC_PROFILE_ROUTE } from '../../../profile';

export interface PlayerPosition {
  id: string;
  isHorizontal: boolean;
  top: number;
  right: number;
  left: number;
  bottom: number;
}

export interface InRoomPlayerListProps {
  noOfPlayers: number | null;
  players: PlayerInfo[];
  nextTurn: string;
  setPlayerPosition?: Dispatch<SetStateAction<PlayerPosition[]>>;
  isHorizontal?: boolean;
}

export const InRoomPlayerList: FC<InRoomPlayerListProps> = ({
  noOfPlayers,
  players,
  nextTurn,
  setPlayerPosition,
  isHorizontal = false,
}) => {
  const avatarColors = useRandomColor(noOfPlayers);

  const leaveRoom = useLeaveRoom();

  if (!noOfPlayers) {
    return (
      <ErrorLayout
        outerBoxProps={{
          height: `auto`,
        }}
      >
        <Button
          variant="contained"
          onClick={leaveRoom(
            `Are you sure you want to leave this room?`,
            false
          )}
        >
          Leave Room
        </Button>
      </ErrorLayout>
    );
  }

  return (
    <PlayersAvatarWrapper isHorizontal={isHorizontal}>
      {players.map((player, i) => {
        return (
          <Box
            key={i}
            display="flex"
            flexDirection="column"
            gap={{
              sm: 0.3,
              lg: 1,
            }}
            alignItems="center"
          >
            <PlayerAvatar
              isHorizontal={isHorizontal}
              setPlayerPosition={setPlayerPosition}
              player={player}
              nextTurn={nextTurn}
              avatarColors={avatarColors}
              i={i}
            />
            <Chip
              label={player.name}
              size="small"
              sx={theme => ({
                maxWidth: 90,
                height: {
                  sm: 16,
                  lg: 'inherit',
                },
                boxShadow:
                  nextTurn === player.id
                    ? `0 0 20px ${theme.palette.success.main}`
                    : 'none',
                backgroundColor: findAvatarBgColor(
                  player.id,
                  nextTurn,
                  avatarColors[i],
                  theme,
                  player.cardsLeft
                ),
                fontSize: { lg: '0.9rem', sm: '0.65rem' },
                fontWeight: 500,
              })}
            />
          </Box>
        );
      })}
    </PlayersAvatarWrapper>
  );
};

export interface PlayerAvatarProps {
  player: PlayerInfo;
  nextTurn: string;
  avatarColors: string[];
  i: number;
  playerAvatarSx?: SxProps<Theme>;
  setPlayerPosition?: Dispatch<SetStateAction<PlayerPosition[]>>;
  isHorizontal?: boolean;
}

export const PlayerAvatar: FC<PlayerAvatarProps> = forwardRef<
  HTMLDivElement,
  PlayerAvatarProps
>(
  (
    {
      player,
      nextTurn,
      avatarColors,
      i,
      playerAvatarSx,
      setPlayerPosition,
      isHorizontal,
    },
    ref
  ) => {
    const theme = useTheme();
    const { sx, src } = avatarSxAndSrc(
      player.id,
      nextTurn || '',
      avatarColors[i],
      player.cardsLeft,
      player.photoURL
    )(theme);

    const playerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
      if (playerRef.current && setPlayerPosition) {
        const pos = playerRef.current.getBoundingClientRect();
        setPlayerPosition(prev => {
          if (prev.some(p => p.id === player.id)) {
            return prev;
          }
          return [
            ...prev,
            {
              id: player.id,
              isHorizontal: !!isHorizontal,
              top: pos.top,
              right: pos.right,
              left: pos.left,
              bottom: pos.bottom,
            },
          ];
        });
      }
    }, [isHorizontal, setPlayerPosition, player.id]);

    return (
      <div ref={playerRef}>
        <BhabiLink
          to={[PUBLIC_PROFILE_ROUTE.EXACT_PATH, '?user_id=', player.id].join(
            ''
          )}
        >
          <UserAvatarStateBadge state={player.state}>
            <Avatar
              sx={{
                ...sx,
                ...playerAvatarSx,
              }}
              sizes="small"
              className="player-avatar"
              ref={ref}
              src={src}
            >
              {playerNameOrPhotoURL(
                player.id,
                player.cardsLeft,
                nextTurn,
                player.photoURL,
                player.name
              )}
            </Avatar>
          </UserAvatarStateBadge>
        </BhabiLink>
      </div>
    );
  }
);

PlayerAvatar.displayName = 'PlayerAvatar';

function playerNameOrPhotoURL(
  playerID: string,
  cardsLeft: number,
  nextTurn?: string | null,
  photoURL?: string,
  name?: string
) {
  if (nextTurn === playerID) {
    return cardsLeft;
  }

  if (!photoURL && name?.length) {
    return name[0].toUpperCase();
  }

  return null;
}

function avatarSxAndSrc(
  playerID: string,
  nextTurn: string,
  avatarColor: string,
  cardsLeft: number,
  photoURL: string
) {
  return (theme: Theme) => {
    const bgColor = findAvatarBgColor(
      playerID,
      nextTurn,
      avatarColor,
      theme,
      cardsLeft
    );

    const isCurrentPlayerWithNextTurn = nextTurn === playerID;
    const width = {
      lg: 40,
      sm: 26,
    };
    const boxShadow = isCurrentPlayerWithNextTurn
      ? `0 0 20px ${theme.palette.success.main}`
      : 'none';
    const src = photoURL?.length
      ? {
          src: isCurrentPlayerWithNextTurn ? '' : photoURL,
        }
      : {};

    return {
      sx: {
        bgcolor: bgColor,
        width,
        height: width,
        boxShadow,
        color: theme.palette.text.primary,
        fontSize: '0.9rem',
        fontWeight: 700,
      },
      src: src.src,
    };
  };
}
